import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@purespectrum1/ui/button';
import { SliderModule } from '@purespectrum1/ui/slider';
import { TabsModule } from '@purespectrum1/ui/tabs';
import { SelectDropdownModule } from '@purespectrum1/ui/select-dropdown';
import { ModalModule } from '@purespectrum1/ui/modal';
import { Daterangepicker } from 'ng2-daterangepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AuthGuard } from '../auth/auth.guard';
import { SortTableModule } from '../sort-table-directive/sort-table.module';
import { ManageSuppliersComponent } from './manage-suppliers/manage-suppliers.component';
import { InvoiceOperationComponent } from './invoice-operation/invoice-operation.component';
import { BuyerSettingsComponent } from './buyer-settings/buyer-settings.component';
import { HashingComponent } from './buyer-settings/hashing/hashing.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VariableMappingComponent } from './buyer-settings/variable-mapping/variable-mapping.component';
import { AdjustmentsReconciliationComponent } from './adjustments-reconciliation/adjustments-reconciliation.component';
import { ReconcilationComponent } from './adjustments-reconciliation/reconcilation/reconcilation.component';
import { ManualFixComponent } from './adjustments-reconciliation/manual-fix/manual-fix.component';
import { SurveyLaunchComponent } from './finalize-survey/survey-launch.component';
import { ManageInvoiceComponent } from './invoice-operation/manage-invoice/manage-invoice.component';
import { SearchSurveyInvoiceComponent } from './invoice-operation/search-survey-to-invoice/search-survey-invoice.component';
import { ConfigEnabledModule } from '../shared/ui/config-enabled/config-enabled.module';
import { AdjustmentModule } from '../shared/ui/adjustment/adjustment.module';
import { LayoutModule } from '../layout/layout.module';
import { HasRoleModule } from '../shared/ui/has-role/has-role.module';
import { DropdownModule } from '@purespectrum1/ui/dropdown';
import { DecipherComponent } from './buyer-settings/decipher/decipher.component';
import { CreateSurveyModule } from '../create-surveys/create-survey.module';
import { ImportDecipherSurveysIframeComponent } from './import-decipher-surveys-iframe/import-decipher-surveys-iframe.component';
import { LegacyIframeModule } from '../shared/ui/legacy-iframe/legacy-iframe.module';
import { EnvironmentConfig } from '@purespectrum1/ui/marketplace/shared/interfaces/environment.interface';
import { environment } from 'src/environments/environment';
import {
  SurveyLaunchModule,
  CreateSurveyModule as MarketplaceCreateSurveyModule,
  MissingMarkersModalModule,
} from '@purespectrum1/ui/marketplace';
import { QuotaMapperComponent } from './finalize-survey/quota-mapper/quota-mapper.component';
import { BuyerRedirectGuideComponent } from './finalize-survey/buyer-redirect-guide/buyer-redirect-guide.component';
import { GoogleTagManagerModule } from '@purespectrum1/ui/tracking';
import { ImportDecipherSurveysComponent } from './import-decipher-surveys/import-decipher-surveys.component';
import { ConditionsMappingComponent } from './import-decipher-surveys/conditions-mapping/conditions-mapping.component';
import { MappingModalComponent } from './import-decipher-surveys/mapping-modal/mapping-modal.component';
import { SupplierGroupModalComponent } from './manage-suppliers/supplier-group-modal/supplier-group-modal.component';
import { BillingNumberRulesComponent } from './buyer-settings/billing-number-rules/billing-number-rules.component';
import { InputRestrictionModule } from '../shared/ui/input-restriction/input-restriction.module';
import { TooltipModule } from '@purespectrum1/ui';
import { IrGeneratorChatComponent } from '../layout/ir-generator/ir-generator-chat/ir-generator-chat.component';
import { AddNewQualifictionModalComponent } from './import-decipher-surveys/add-new-qualifiction-modal/add-new-qualifiction-modal.component';
import { AllocationClass } from './manage-suppliers/suppliers-manage-class/allocation.class';
import { FlexibilityClass } from './manage-suppliers/suppliers-manage-class/flexibility.class';
import { UserGuard } from './../auth/user.guard';
import { USER_TYPES } from '../operator/manage-companies/manage-companies-form/constant';
import { MultiCountryInfoListingModule } from '../shared/ui/multi-country-info-listing/multi-country-info-listing.module';
import { TableModule } from '@purespectrum1/ui/table';

@NgModule({
  declarations: [
    InvoiceOperationComponent,
    ManageSuppliersComponent,
    BuyerSettingsComponent,
    HashingComponent,
    ResetPasswordComponent,
    VariableMappingComponent,
    AdjustmentsReconciliationComponent,
    ReconcilationComponent,
    ManualFixComponent,
    SurveyLaunchComponent,
    ManageInvoiceComponent,
    SearchSurveyInvoiceComponent,
    DecipherComponent,
    ImportDecipherSurveysIframeComponent,
    QuotaMapperComponent,
    BuyerRedirectGuideComponent,
    ImportDecipherSurveysComponent,
    ConditionsMappingComponent,
    MappingModalComponent,
    SupplierGroupModalComponent,
    BillingNumberRulesComponent,
    AddNewQualifictionModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SliderModule,
    TabsModule,
    ButtonModule,
    ModalModule,
    Daterangepicker,
    SortTableModule,
    InfiniteScrollModule,
    SelectDropdownModule,
    ConfigEnabledModule,
    AdjustmentModule,
    LayoutModule,
    HasRoleModule,
    DropdownModule,
    SliderModule,
    CreateSurveyModule,
    TooltipModule,
    MarketplaceCreateSurveyModule.forChild({
      environment,
    } as EnvironmentConfig),
    LegacyIframeModule,
    SurveyLaunchModule.forChild({ environment } as EnvironmentConfig),
    GoogleTagManagerModule,
    InputRestrictionModule,
    MissingMarkersModalModule,
    RouterModule.forChild([
      {
        path: 'buyer',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'manage-suppliers',
            component: ManageSuppliersComponent,
            canActivate: [UserGuard],
            data: { roles: [USER_TYPES.BUYER] },
          },
          {
            path: 'manage-buyer-settings',
            component: BuyerSettingsComponent,
            canActivate: [UserGuard],
            data: { roles: [USER_TYPES.BUYER] },
          },
          {
            path: 'reset-password',
            component: ResetPasswordComponent,
          },
          {
            path: 'adjustments',
            component: AdjustmentsReconciliationComponent,
            canActivate: [UserGuard],
            data: { roles: [USER_TYPES.BUYER, USER_TYPES.OPERATOR] },
          },
        ],
      },
      {
        path: 'launch-survey/:id',
        canActivate: [AuthGuard],
        component: SurveyLaunchComponent,
      },
      {
        path: 'buyerRedirectGuide',
        component: BuyerRedirectGuideComponent,
      },
      {
        path: 'quota-mapper/:id',
        canActivate: [AuthGuard],
        component: QuotaMapperComponent,
      },
      {
        path: 'import-decipher-survey',
        canActivate: [AuthGuard],
        component: ImportDecipherSurveysComponent,
      },
      {
        path: 'invoice-operations',
        component: InvoiceOperationComponent,
        canActivate: [UserGuard],
        data: { roles: [USER_TYPES.BUYER, USER_TYPES.OPERATOR] },
      },
      {
        path: 'ir-generator',
        component: IrGeneratorChatComponent,
      },
    ]),
    MultiCountryInfoListingModule,
    TableModule,
  ],
  providers: [AllocationClass, FlexibilityClass],
})
export class BuyerModule {}
