import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ButtonModule } from '@purespectrum1/ui/button';
import { ModalModule } from '@purespectrum1/ui/modal';
import { LoaderModule } from '@purespectrum1/ui/loader';
import { ToasterModule } from '@purespectrum1/ui/toaster-service';

import { AppComponent } from './app.component';

import { RequestInterceptor } from './intercepters/request.interceptor';
import { ErrorInterceptor } from './intercepters/error.interceptor';
import { CaptchaInterceptor } from './intercepters/captcha.interceptor';

import { SupplierModule } from './supplier/supplier.module';
import { BuyerModule } from './buyer/buyer.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LayoutModule } from './layout/layout.module';
import { OperatorSettingsModule } from './operator-settings/operator-settings.module';
import { DownloadReportsModule } from './buyer/download-reports/download-reports.module';
import { OperatorModule } from './operator/operator.module';
import { PsToolsModule } from './ps-tools/ps-tools.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { environment } from '../environments/environment';

import { QuestionsLibraryModule } from './questions-library/questions-library.module';
import { CreateSurveyModule } from './create-surveys/create-survey.module';
import { CapitalizePipeModule } from './shared/pipes/capitalize-pipe.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { EditSurveyConfirmationModalComponent } from './shared/ui/edit-survey-confirmation-modal/edit-survey-confirmation-modal.component';
import { SearchFilterPipeModule } from './shared/pipes/filter-pipe.module';
import { ConfirmationModalModule } from './shared/ui/confirmation-modal/confirmation-modal.module';
import { GT_MEASUREMENT_ID } from './constants/global';
import {
  ChurnZeroModule,
  GoogleTagManagerModule,
  ChurnZeroService,
  NEWRELIC,
} from '@purespectrum1/ui/tracking';
import { BulkSurveyStatusChangeModalModule } from './shared/ui/bulk-survey-status-change-modal/bulk-survey-status-change-modal.module';
import { _newrelic } from './utils/newrelic';

const config: SocketIoConfig = {
  url: environment.pureSpectrum.url,
  options: {
    autoConnect: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    transports: ['websocket'],
  },
};

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    EditSurveyConfirmationModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    ToasterModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    AuthModule,
    DashboardModule,
    DownloadReportsModule,
    SupplierModule,
    OperatorSettingsModule,
    BuyerModule,
    ButtonModule,
    OperatorModule,
    PsToolsModule,
    ModalModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    QuestionsLibraryModule,
    CreateSurveyModule,
    CapitalizePipeModule,
    ConfirmationModalModule,
    SocketIoModule.forRoot(config),
    SearchFilterPipeModule,
    BulkSurveyStatusChangeModalModule,
    LoaderModule.forRoot({
      loaderUrls: ['com', 'localhost'],
      excludeUrls: [
        // surveys dashboard
        '/v2/surveys/count',
        '/v2/surveys/invoiced/billig_id',
        // select service account (service operator)
        '/getBuyers',
        // create surveys
        '/v2/supplier-blends',
        '/supplier/getFlexibilityPricesParams',
        '/feasibility',
        '/v1/pricing-reco',
        '/v1/calculate-permutations',
        // reports pages
        '/feature/access',
        '/buyerSettings',
        '/countries',
        '/invoice/companies',
        '/survey-statistcs',
        '/searchAutocomplete',
        '/pricing/notifications',
      ],
    }),
    GoogleTagManagerModule.forRoot({
      trackingId: `${GT_MEASUREMENT_ID}`,
      trackPageviews: environment.production,
      trackHotjarGTM: environment.production,
      enableHotjarEvent: environment.ENABLE_HOTJAR_EVENT,
    }),
    ChurnZeroModule.forRoot(environment.churnzeroUrl),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CaptchaInterceptor, multi: true },
    { provide: NEWRELIC, useValue: _newrelic },
    ChurnZeroService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
