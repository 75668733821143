<div class="container">
  <div class="row">
    <form (ngSubmit)="updateGlobalSettings()" [formGroup]="form">
      <div class="col-xs-12 col-sm-11 col-md-8 survey">
        <div class="row align-center">
          <div class="col-md-6">PSID V3+ SC</div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enablePSIDV3Plus'"
              formControlName="enablePSIDV3Plus"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div>
          <div class="row align-center">
            <div class="col-md-6">Dynamic Profiler</div>
            <div class="col-md-6">
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [id]="'dynamic_profiler'"
                formControlName="dynamic_profiler"
              >
              </ps-ui-capsule-radio-button>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">Max Number Of Questions</div>
            <div class="col-md-6">
              <input
                type="number"
                name="dp_max_question_count"
                formControlName="dp_max_question_count"
              />
            </div>
          </div>
          <div formGroupName="fma">
            <div class="row align-center">
              <div><b>Automated Yield Managment</b></div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">FMA Minimum EPC</div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="minimumEPC"
                  formControlName="minimumEPC"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">FMA Minimum CPI</div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="minimumCPI"
                  formControlName="minimumCPI"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">Minimum PPM (Price Per Minute)</div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="minimumPPM"
                  formControlName="minimumPPM"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">
                FMA Minimum QPG (Quota Proximity to Goal)
              </div>
              <div class="col-sm-6">
                <input
                  type="number"
                  name="minimumQPG"
                  formControlName="minimumQPG"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">
                FMA Minimum OQP (Over Quota by Percent)
              </div>
              <div class="col-sm-6">
                <input
                  type="number"
                  name="minimumOQP"
                  formControlName="minimumOQP"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">
                FMA Minimum BDP (Buyer Drop by Percent)
              </div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="minimumBDP"
                  formControlName="minimumBDP"
                />
              </div>
            </div>
          </div>
          <div formGroupName="fmaDAL">
            <div class="row align-center">
              <div><b>Daily Auto Launch TC</b></div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">FMA Minimum EPC</div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="fmaDALMinimumEPC"
                  formControlName="minimumEPC"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">FMA Minimum CPI</div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="fmaDALMinimumCPI"
                  formControlName="minimumCPI"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">FMA Minimum PPM (Price Per Minute)</div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="fmaDALMinimumPPM"
                  formControlName="minimumPPM"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">
                FMA Minimum OQP (Over Quota by Percent)
              </div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="fmaDALMinimumOQP"
                  formControlName="minimumOQP"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">
                FMA Minimum BDP (Buyer Drop by Percent)
              </div>
              <div class="col-md-6">
                <input
                  type="number"
                  name="fmaDALMinimumBDP"
                  formControlName="minimumBDP"
                />
              </div>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6 asr-statuses-style">
              ASR redirection statuses
            </div>
            <div class="col-md-6">
              <ng-select
                class="custom"
                [clearable]="false"
                [items]="psTransactionStatuses"
                bindLabel="name"
                placeholder="Pick status"
                [multiple]="true"
                [(ngModel)]="asrTransactionStatusesObj"
                [ngModelOptions]="{ standalone: true }"
                (change)="selectAsrTransactionStatuses($event)"
              >
              </ng-select>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">Block Size Min</div>
            <div class="deprcateSwitch col-md-6">
              <input
                type="number"
                class="survey-style width100"
                name="block_size_min"
                formControlName="block_size_min"
              />
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">Block Size Max</div>
            <div class="deprcateSwitch col-sm-6">
              <input
                type="number"
                class="survey-style width100"
                name="block_size_max"
                formControlName="block_size_max"
              />
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">Block Size Coefficient</div>
            <div class="deprcateSwitch col-md-6">
              <input
                type="number"
                step="0.1"
                class="survey-style width100"
                name="block_size_coefficient"
                formControlName="block_size_coefficient"
              />
            </div>
          </div>
          <div formGroupName="f2f">
            <div class="row align-center">
              <div>
                <strong>F2F - Fast to Field</strong>
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">F2F Enable</div>
              <div class="col-md-6">
                <ps-ui-capsule-radio-button
                  class="capsule-button-row"
                  [id]="'f2fEnabled'"
                  formControlName="f2fEnabled"
                >
                </ps-ui-capsule-radio-button>
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">Percentage Allocation</div>
              <div class="col-md-6">
                <input
                  type="text"
                  [maxlength]="3"
                  name="percentAllocation"
                  formControlName="percentAllocation"
                  (keyup)="restrictPercentageOverflow()"
                  psInputRestriction="[0-9]"
                />
              </div>
            </div>
            <div class="row align-center">
              <div class="col-md-6">Percentage Field Time</div>
              <div class="col-md-6">
                <input
                  type="text"
                  [maxlength]="3"
                  name="percentFieldTime"
                  formControlName="percentFieldTime"
                  (keyup)="restrictPercentageOverflow()"
                  psInputRestriction="[0-9]"
                />
              </div>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">Enable reCAPTCHA</div>
            <div class="col-md-6">
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                formControlName="enableCaptcha"
              >
              </ps-ui-capsule-radio-button>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">DeviceForensIQ</div>
            <div class="col-md-6">
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [id]="'enableDeviceForensIQ'"
                formControlName="enableDeviceForensIQ"
              >
              </ps-ui-capsule-radio-button>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">reCAPTCHA V3</div>
            <div class="col-md-6">
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [id]="'enableRecaptchaV3'"
                formControlName="enableRecaptchaV3"
              >
              </ps-ui-capsule-radio-button>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">hCAPTCHA</div>
            <div class="col-md-6">
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [id]="'enableHCaptcha'"
                formControlName="enableHCaptcha"
              >
              </ps-ui-capsule-radio-button>
            </div>
            <div class="col-md-6">Reconciliation Percentage Limit</div>
            <div class="col-md-6">
              <input
                type="number"
                name="reconPercentageLimit"
                formControlName="reconPercentageLimit"
              />
            </div>
          </div>
          <div class="hasing-buttons mt-6 col-md-2">
            <button type="submit" color="primary">Submit</button><br />
          </div>
        </div>
      </div>
      <div class="backbutton col-md-12 mt-6">
        <button
          id="dashboard"
          type="button"
          psUiButton
          size="large"
          color="primary"
          [routerLink]="['/dashboard/']"
        >
          Back to Dashboard
        </button>
      </div>
    </form>
  </div>
</div>
