import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalService, TemplateService } from '@purespectrum1/ui';
import {
  TableExpandableRowDirective,
  TableElementKey,
  TableBorder,
  TableStateEvent,
  TableComponent,
} from '@purespectrum1/ui/table';
import {
  BehaviorSubject,
  from,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  startWith,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';
import { BULK_STATUS_CHANGE_MODAL } from '../../constants/modal-constants';
import { notifyMessage } from '../../constants/notify-message';
import { BillingService } from '../../shared/services/billing-service/billing.service';
import { BulkInvoiceHelperService } from '../../shared/services/bulk-invoice/bulk-invoice-helper.service';
import { BuyerApiService } from '../../shared/services/buyer-api/survey.service';
import { CommonService } from '../../shared/services/common-service/common-service';
import { BulkSurveyStatusChangeModalComponent } from '../../shared/ui/bulk-survey-status-change-modal/bulk-survey-status-change-modal.component';
import { AuthService } from '../../auth/auth.service';
import { INVOICE_TYPE } from '../../constants/invoice-type';
import { STATUS_BUTTONS } from '../../constants/status-buttons';
import { SURVEY_STATUS } from '../../constants/survey-status';
import {
  BuyerSurveyResponse,
  SurveyListing,
  SurveyUpdate,
} from '../../shared/services/buyer-api/survey.interface';
import { SurveyStatus } from '../../utils/survey-status';
import { ChangeSurveyStatusModalComponent } from '../change-survey-status-modal/change-survey-status-modal.component';
import { Constants } from '../dashboard-constants';
import { DashboardPreferenceModalComponent } from '../dashboard-preference-modal/dashboard-preference-modal.component';
import { DashboardPreferenceState } from '../domain/dashboard-preference';
import { DashboardTab } from '../domain/dashboard-tab';
import { TCCreateAction } from '../types';
import { SelectedWidget, Widget } from '../widget/widget';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { SurveyTableFilterDelegate } from './domain/survey-table-filter';
import { SurveysLength } from './domain/surveys-length';
import { EachCountry } from '../../shared/interfaces/common-service.interface';
import { SurveyRouterLinkPipe } from '../pipes/survey-router-link.pipe';
import { Buyer } from '../../operator-settings/operator-settings.interface';
import { Pagination } from '../../shared/types/pagination';
import { CompanyInfo } from '../../shared/interfaces/company.interface';
import { CompanyService } from '../../shared/services/company/company.service';
import {
  DashboardStateService,
  EMPTY_DASHBOARD_STATE,
} from '../services/dashboard-state.service';
import { TreeDropdownOption } from '@purespectrum1/ui/marketplace/create-surveys/shared/interfaces/tree-dropdown-interface';
import { TemplateListResponse } from '@purespectrum1/ui/marketplace/create-surveys/layout/template-modal/template-modal.interface';
import { DashboardSelectedSurveys } from '../domain/dasboard-survey-selected';

type DeleteAction = {
  id: number;
  status: number;
};

@Component({
  selector: 'ps-survey-table',
  templateUrl: './survey-table.component.html',
  styleUrls: ['./survey-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyTableComponent implements OnInit, OnDestroy {
  @Input()
  public preferences!: DashboardPreferenceState;

  @Input()
  set tab(tab: DashboardTab) {
    this._tabCommand$.next(tab);
  }

  @Input()
  public surveys: SurveyListing[] = [];

  @Input()
  public set selected(surveys: SurveyListing[]) {
    this.selectedKeys = surveys.map((survey) => survey.surveyId);
  }

  @Input()
  public countries: EachCountry[] = [];

  @Input()
  public isDasaboard: boolean = false;

  @Input()
  public isMultiCountryWidget: boolean = false;

  @Output()
  public companyChange = new EventEmitter<Buyer>();

  @Output()
  public paginate = new EventEmitter();

  @Output()
  public clone = new EventEmitter<number>();

  @Output()
  public delete = new EventEmitter<number[]>();

  @Output()
  public tc = new EventEmitter<TCCreateAction>();

  @Output()
  public downloadReport = new EventEmitter<number>();

  @Output()
  public selectedChange = new EventEmitter<SurveyListing[]>();

  @ViewChild(TableExpandableRowDirective)
  public expandable!: TableExpandableRowDirective;

  @HostBinding('style.--custom-table-header-padding')
  public get tableHeaderPadding() {
    return this.preferences.enableBulkEditAndSorting ? '3px' : '8px';
  }

  @ViewChild(TableComponent)
  public table!: TableComponent<Record<string, unknown>>;

  public get supplier(): boolean {
    return this.preferences.user === 'supplier';
  }

  public get length() {
    return new SurveysLength(this.surveys).calculate();
  }

  public get border(): TableBorder {
    return 'bottom';
  }

  get isCompanySelectionAllowed() {
    return (
      this._auth.userType === 'operator' &&
      !this._auth.isLoggedInAsServiceBuyer()
    );
  }

  public SURVEY_LISTING_TABLE_HEADERS = Constants.SURVEY_LISTING_TABLE_HEADERS;
  public RECONTACT_SURVEY_SAMPLE_TYPE = Constants.RECONTACT_SURVEY_SAMPLE_TYPE;
  public SURVEY_STATUS = SurveyStatus;
  public statusButtons = STATUS_BUTTONS;
  public surveyStatus: { value: number; name: string }[] = [];
  public currentCompany?: CompanyInfo;
  public companies: CompanyInfo[] = [];
  public companies$!: Observable<CompanyInfo[]>;
  public state: TableStateEvent = EMPTY_DASHBOARD_STATE;
  public templates$!: Observable<TemplateListResponse[]>;

  public selectedWidget = SelectedWidget.empty();
  public managing = true;
  public edit: boolean = false;
  public selectedSurveys: TableElementKey<string>[] = [];
  public selection: boolean = false;
  public filter = new SurveyTableFilterDelegate();
  public selectedKeys: number[] = [];
  public tab$!: Observable<DashboardTab>;

  public templateOption: TreeDropdownOption = {
    uniqueKey: 'id',
    displayKey: 'name',
    navigationKey: 'templates',
    removeItem: false,
    icon: 'fa fa-file-text-o',
    noContentMsg: 'No Template created yet',
    mcIconIdentifier: 'is_mc_template',
  };

  public templateFolderOption: TreeDropdownOption = {
    uniqueKey: 'id',
    displayKey: 'name',
    navigationKey: 'subfolders',
    headerLabel: 'My Templates',
    removeItem: false,
    icon: 'fa fa-folder',
    noContentMsg: 'No Template created yet', // Even though it is template-folder content, this message is for ease of user
    mcIconIdentifier: 'is_mc_template',
  };

  private _deleteCommand$ = new Subject<DeleteAction>();
  private _tabCommand$ = new BehaviorSubject<DashboardTab>(
    DashboardTab.from(Constants.SURVEY_TABS[0])
  );
  private _subscriptions = new Subscription();
  private _tab!: DashboardTab;
  private _searchCommand$ = new Subject<string>();
  public healtcheckEnabled: boolean = false;

  constructor(
    // TODO: Remove service injetion.
    // This component should be presentation only
    private _buyerApiService: BuyerApiService,
    private _billingService: BillingService,
    private _commonService: CommonService,
    private _companyService: CompanyService,
    private _bulkInvoiceHelperService: BulkInvoiceHelperService,
    private _templateService: TemplateService,
    private _auth: AuthService,
    private _toastr: ToasterService,
    // End
    private _dashboardStateService: DashboardStateService,
    private readonly _route: Router,
    private readonly _modal: ModalService
  ) {}

  public ngOnInit(): void {
    this.state = this._dashboardStateService.lastState;

    this.tab$ = merge(
      this._auth.loggedInAsServiceBuyer$.pipe(map(() => this._tab)),
      this._tabCommand$.pipe(
        tap((tab) => {
          this._tab = tab;
        })
      )
    ).pipe(
      debounceTime(400),
      tap(() => {
        this.surveyStatus = this._surveyStatus();
      })
    );

    const _delete = this._deleteCommand$
      .pipe(
        switchMap((action) =>
          this._modal
            .open(ChangeSurveyStatusModalComponent, {
              data: {
                surveyId: action.id,
                status: action.status,
                toDelete: true,
              },
              width: '450px',
              height: '18.75rem',
            })
            .onClose$.pipe(
              filter((msg) => msg === Constants.MODAL_MESSAGE.DELETE_SURVEY),
              mapTo(action)
            )
        )
      )
      .subscribe((action) => this.delete.emit([action.id]));

    this.initiateCompanySearch();
    this.templates$ = this._templateService.getUpdatedTemplateList().pipe(
      switchMap((templates) => {
        const filtered = this.state.filter.find((f) => f.field === 'template');

        return from(templates).pipe(
          map((template) => {
            template.templates = template.templates.map((value) => ({
              ...value,
              isChecked: filtered?.values.includes(value.name),
            }));

            return template;
          }),
          toArray()
        );
      })
    );

    this.healtcheckEnabled = this._auth.buyerConfig!.enableHealthMetrics;
    this._subscriptions.add(_delete);
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public onTableStateChange(state: TableStateEvent): void {
    this._dashboardStateService.update(state);
    if (this.isCompanySelectionAllowed) {
      if (
        this.currentCompany &&
        !state.filter.find(
          (data) =>
            data.field === this.SURVEY_LISTING_TABLE_HEADERS.BUYER_ACCOUNT &&
            data.type === 'option'
        )
      ) {
        this.companyChange.emit();
        this.companies = [];
        this.currentCompany = undefined;
        this._dashboardStateService.company = undefined;
        this._dashboardStateService.selected = DashboardSelectedSurveys.empty();
        this._searchCommand$.next();
        return;
      }

      if (
        state.searchText &&
        state.searchField === this.SURVEY_LISTING_TABLE_HEADERS.BUYER_ACCOUNT
      ) {
        this._searchCommand$.next(state.searchText);
        return;
      }

      const company = this.getBuyerCompany(state);
      if (company && company?.id !== this.currentCompany?.id) {
        this.currentCompany = company;
        this.companyChange.emit(this.currentCompany);
        return;
      }
    }
  }

  public onScrolled(): void {
    this.paginate.emit();
  }

  public initiateCompanySearch() {
    const search$ = this._searchCommand$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    );

    this.companies = this._dashboardStateService.company
      ? [this._dashboardStateService.company]
      : [];

    this.currentCompany = this._dashboardStateService.company;

    this.companies$ = search$.pipe(
      switchMap((search) =>
        this._companyService
          .getCompaniesPage(Pagination.first(), search, 'buyer')
          .pipe(
            map((response) => {
              return !!search ? response : { companies: [] };
            }),
            catchError((error) => {
              this._toastr.error(error.error.msg);
              return of({ count: 0, companies: [] });
            })
          )
      ),
      map((response) => {
        this.companies = response.companies;
        return response.companies;
      }),
      startWith(this.companies)
    );
  }

  public getBuyerCompany(state: TableStateEvent): CompanyInfo | undefined {
    const buyerFilter = state.filter.filter(
      (b) =>
        b.field === this.SURVEY_LISTING_TABLE_HEADERS.BUYER_ACCOUNT &&
        b?.values?.[0]?.length
    );
    const companyName = buyerFilter?.[buyerFilter.length - 1]?.values?.[0];
    return this.companies.find((c) => c.name === companyName);
  }

  public checkDisableSurveyStatus(survey: SurveyListing) {
    return (
      [SURVEY_STATUS.DRAFT, SURVEY_STATUS.INVOICE].indexOf(survey.status) >
        -1 ||
      this.preferences.user === 'supplier' ||
      (survey.status === SURVEY_STATUS.CLOSED &&
        survey?.company?.generate_invoice === INVOICE_TYPE.MONTHLY)
    );
  }

  public openDashboardPreferenceModal() {
    this._modal.open(DashboardPreferenceModalComponent, {
      height: '80%',
      width: '80%',
    });
  }

  public onWidgetPressed(widget: Widget, survey: SurveyListing, index: number) {
    if (
      !this.selectedWidget.for(survey) ||
      this.selectedWidget.equals(widget)
    ) {
      this.expandable.expand(index);
    }

    this.selectedWidget = this.selectedWidget.select(widget, survey);
  }

  public onDetails(id: number, status: number, samplesType: number) {
    if (status === SurveyStatus.Draft) {
      if (samplesType === Constants.RECONTACT_SURVEY_SAMPLE_TYPE) {
        this._route.navigate(['recontact-survey', id]);
      } else {
        this._route.navigate([`/launch-survey/${id}`]);
      }
    } else {
      this._route.navigate([`/dashboard/${id}`]);
    }
  }

  public onOpenTrafficChannelManage(): void {
    this.managing = false;
  }

  public onCloseManageMenu(): void {
    this.managing = true;
  }

  public onClone(id: number): void {
    this.clone.emit(id);
  }

  public onDelete(id: number, status: number): void {
    this._deleteCommand$.next({ id, status });
  }

  public onCreateTC(type: string, request: string, id: number): void {
    this.tc.emit({
      type,
      request,
      id,
    });
  }

  public onDownloadReport(id: number): void {
    this.downloadReport.emit(id);
  }

  public onSurveysSelected(ids: TableElementKey<string>[]) {
    const selection = this.surveys.filter((survey) =>
      ids.some((id) => id === survey.surveyId)
    );

    this.selectedChange.emit(selection);
  }

  public onTableCellClicked(survey: SurveyListing): void {
    const route = new SurveyRouterLinkPipe().transform(survey);
    this._route.navigate(route);
  }

  public onSelectedTemplates(templates: { id: number; name: string }[]): void {
    this.state = {
      ...this.state,
      filter: [
        {
          field: 'template',
          type: 'option' as const,
          values: templates.map((template) => template.name.toString()),
        },
      ],
    };

    this._dashboardStateService.update(this.state);
  }

  // TODO: This should be refacted and extracted from this component when we integrate
  // invoice status changes in the new bulk edit dashboard
  async onChangeStatus(
    surveyId: number,
    status: string,
    billingId: string,
    survey_pause_threshold: number,
    is_survey_pause_threshold_triggered: boolean,
    aCpi: number
  ) {
    const isBillingConfirmationRequired = this._isBillingConfirmationRequired(
      status,
      billingId
    );
    if (isBillingConfirmationRequired) {
      const shouldProceed = await this._billingService.openBillingNumberModal();
      if (!shouldProceed) {
        return;
      }
    }
    const [isProceed, isBulkInvoicingCase, surveysToClose, surveysToInvoice] =
      await this._checkIfItisBulkInvoicingCase(surveyId, status, billingId);
    if (!isProceed) {
      return;
    }
    if (!isBulkInvoicingCase) {
      return this.openSingleStatusUpdateModal(
        surveyId,
        status,
        survey_pause_threshold,
        is_survey_pause_threshold_triggered,
        aCpi
      );
    }
    return this._updateSurveyStatuses(
      surveyId,
      surveysToClose,
      surveysToInvoice
    );
  }

  private _isBillingConfirmationRequired(status: string, billingId: string) {
    const numberedStatus = Number(this._commonService.getSurveyStatus(status));
    return !!(
      !this._auth.buyerConfig?.enableMandatoryBillingNumber &&
      !billingId &&
      numberedStatus === SurveyStatus.Invoice
    );
  }

  private async _checkIfItisBulkInvoicingCase(
    currentSurveyId: number,
    status: string,
    billingId: string
  ): Promise<[boolean, boolean, SurveyUpdate[], SurveyUpdate[]]> {
    const numberedStatus = Number(this._commonService.getSurveyStatus(status));
    if (numberedStatus !== SurveyStatus.Invoice) {
      return [true, false, [], []];
    }
    return this._checkAndShowModalIfSurveysWithSamePo(
      currentSurveyId,
      numberedStatus,
      billingId
    );
  }

  private async _checkAndShowModalIfSurveysWithSamePo(
    currentSurveyId: number,
    status: SurveyStatus,
    billingId: string
  ) {
    let notInvoicedSurveysWithSamePo =
      await this._fetchLaunchedAndNotInvoicedSurveysWithSamePo(billingId);
    notInvoicedSurveysWithSamePo = notInvoicedSurveysWithSamePo.filter(
      (s) => Number(s.ps_survey_id) !== Number(currentSurveyId)
    );
    if (!notInvoicedSurveysWithSamePo.length) {
      return this._bulkInvoiceHelperService.decideSurveysToChangeStatusOf(
        status,
        BULK_STATUS_CHANGE_MODAL.NO_BULK_INVOICING_ACTION,
        [],
        [currentSurveyId]
      );
    }
    const action = await this._showModalIfInvoicedSurveysWithSamePo(
      notInvoicedSurveysWithSamePo
    );
    return this._bulkInvoiceHelperService.decideSurveysToChangeStatusOf(
      status,
      action,
      notInvoicedSurveysWithSamePo,
      [currentSurveyId]
    );
  }

  private async _fetchLaunchedAndNotInvoicedSurveysWithSamePo(
    billingId: string
  ) {
    if (!billingId) {
      return [];
    }
    return this._buyerApiService
      .getLaunchedAndNotInvoicedSurveysWithSamePo(billingId)
      .toPromise();
  }

  private async _showModalIfInvoicedSurveysWithSamePo(
    notInvoicedSurveysWithSamePo: BuyerSurveyResponse[]
  ) {
    const notInvoicedSurveyIdsWithSamePo =
      this._bulkInvoiceHelperService.fetchNotInvoicedSortedSurveyIdsWithSamePo(
        notInvoicedSurveysWithSamePo
      );
    return this._modal
      .open(BulkSurveyStatusChangeModalComponent, {
        data: {
          thisAndAllBtnText:
            BULK_STATUS_CHANGE_MODAL.BUTTON.THIS_AND_ALL_LISTED.TEXT
              .SURVEY_DETAIL,
          onlyThisBtnText:
            BULK_STATUS_CHANGE_MODAL.BUTTON.ONLY_THIS.TEXT.SURVEY_DETAIL,
          surveyIds: notInvoicedSurveyIdsWithSamePo.join(', '),
        },
        width: '60%',
      })
      .onClose$.toPromise();
  }

  private async _updateSurveyStatuses(
    currentSurveyId: number,
    surveysToClose: SurveyUpdate[],
    surveysToInvoice: SurveyUpdate[]
  ) {
    await this._buyerApiService
      .updateSurveyStatusesInBulk(surveysToClose)
      .toPromise();
    this._buyerApiService
      .updateSurveyStatusesInBulk(surveysToInvoice)
      .subscribe(
        (data) => {
          const surveyUpdateData = Object.keys(data).map((surveyId) => {
            return {
              surveyId: Number(surveyId),
              status: SurveyStatus.Invoice,
            };
          });

          const ids = surveyUpdateData.map((survey) => survey.surveyId);
          this.delete.emit(ids);

          const isBulkInvoiced = Object.keys(data).length > 1;
          this._toastr.success(
            isBulkInvoiced
              ? notifyMessage.successMessage.SURVEY_DETAIL.BULK_SURVEYS_INVOICED
              : notifyMessage.successMessage.SURVEY_DETAIL.SINGLE_SURVEY_INVOICED.replace(
                  '<SURVEY_ID>',
                  String(currentSurveyId)
                )
          );
        },
        (error) => {
          this._toastr.error(error.error?.ps_api_response_message || error.msg);
        }
      );
  }

  public openSingleStatusUpdateModal(
    surveyId: number,
    status: string,
    survey_pause_threshold: number,
    is_survey_pause_threshold_triggered: boolean,
    currentCpi: number
  ): void {
    const surveyUpdateData = {
      surveyId: surveyId,
      status: status,
      survey_pause_threshold,
      is_survey_pause_threshold_triggered,
      currentCpi,
    };
    const modalRef = this._modal.open(ChangeSurveyStatusModalComponent, {
      data: surveyUpdateData,
      width: '450px',
      height: '18.75rem',
    });
    modalRef.onClose$.subscribe((msg) => {
      if (msg === Constants.MODAL_MESSAGE.UPDATE_SURVEY_STATUS) {
        this.delete.emit([surveyUpdateData.surveyId]);
      }
    });
  }

  public _surveyStatus() {
    return Object.entries(SURVEY_STATUS)
      .map(([name, value]) => ({
        value,
        name,
      }))
      .filter(
        (status) =>
          status.name !== 'INVOICE' ||
          ['PO', 'PROJECT'].includes(
            this._auth.companyConfig.generateInvoice || ''
          )
      )
      .filter(
        (status) =>
          status.name !== 'DRAFT' ||
          this._auth.userType !== 'operator' ||
          this._auth.isLoggedInAsServiceBuyer()
      );
  }

  public isDashboardEnabled(survey: SurveyListing): boolean {
    if (survey?.mc?.SVP && survey?.mc?.parent) {
      return true;
    }

    if (
      survey?.mc?.SVP &&
      !survey?.mc?.parent &&
      this._auth.userType === 'operator'
    ) {
      return false;
    }

    return this.isMultiCountryWidget;
  }
}
