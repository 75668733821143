import {
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { BuyerConfig } from '@purespectrum1/ui/marketplace/shared/interfaces/buyer-config.interface';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _authGuard: AuthGuard
  ) {}

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    // Get the flag name from the route data
    const flagName: string = next.data['flagName'];
    // Get the flag value from BuyerConfig
    let fieldName = flagName as keyof BuyerConfig;
    const flagValue =
      this._auth.buyerConfig && this._auth.buyerConfig[fieldName]
        ? this._auth.buyerConfig[fieldName]
        : false;

    if (flagValue) {
      return this._authGuard.canActivate();
    } else {
      // Redirect to an error page
      return this._router.parseUrl('/access-denied');
    }
  }
}
