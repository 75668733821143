'use strict';

import { McSurvey } from '../../shared/services/buyer-api/survey.interface';

export const Constants = Object.freeze({
  INVOICE_OP_TABS: [
    {
      title: 'MANAGE INVOICE',
      key: 'MANAGE_INVOICE',
      active: false,
      allowed: ['operator'],
    },
    {
      title: 'SEARCH SURVEY TO INVOICE',
      key: 'SEARCH_SURVEY_TO_INVOICE',
      active: true,
      allowed: ['buyer', 'operator'],
    },
  ],
  MONTHS_TO_DISPLAY: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  YEARS: [
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
  ],
  DATES: [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ],
});

export const MONTH_NAME_TO_NUMBER: StringObject = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12',
};

interface StringObject {
  [key: string]: string;
}

export const MONTH_NUMBER_TO_NAME: StringObject = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

export interface Tab {
  title: string;
  active: boolean;
  key: string;
}

export interface IReportFilterField {
  dateRange: Date[];
  poNumber: string;
  surveyId: string;
  surveyName: string;
}

export interface Surveys {
  status: number;
  ps_survey_id: number;
  survey_title: string;
  buyerAccount?: string;
  fielded: number;
  goal: number;
  inProgress?: number | string;
  average_cpi: number | string;
  aCpi?: number;
  bip: number | string;
  currentIR: number | string;
  countryCode: string;
  currencySymbol: string;
  company_name: string;
  selected: boolean;
  ps_survey_status: number;
  completes_required: number | string;
  billing_id: number | string;
  survey_localization: string;
  current_incidence: string;
  mc: McSurvey;
}
