import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TabsModule } from '@purespectrum1/ui/tabs';
import { ButtonModule } from '@purespectrum1/ui/button';
import { CapsuleRadioButtonModule } from '@purespectrum1/ui/capsule-radio-button';
import { SliderModule } from '@purespectrum1/ui/slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { OperatorSettingsComponent } from './operator-settings.component';
import { CurrencySettingsComponent } from './currency-settings/currency-settings.component';
import { GenpopSettingsPipe } from './genpop-settings/genpop-settings.pipe';

import { MarginSettingsComponent } from './margin-settings/margin-settings.component';
import { AuthGuard } from '../auth/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IPSOSMappingsComponent } from './ipsos-mappings/ipsos-mappings.component';
import { ExternalBuyerMappingsComponent } from './external-buyer-mappings/external-buyer-mappings.component';
import { ConfigSettingsComponent } from './config-settings/config-settings.component';
import { GlobalSettingsComponent } from './global-settings/global-settings.component';
import { SelectDropdownModule } from '@purespectrum1/ui/select-dropdown';
import { SupplierSettingsComponent } from './supplier-settings/supplier-settings.component';
import { ManageFeasibilityComponent } from './manage-feasibility/manage-feasibility.component';
import { GenpopSettingsComponent } from './genpop-settings/genpop-settings.component';
import { ManageCensusComponent } from './manage-census/manage-census.component';
import { GenpopFileuploadModalComponent } from './genpop-settings/genpop-fileupload-modal/genpop-fileupload-modal.component';
import { ModalModule } from '@purespectrum1/ui';

import { SamplifyMappingsComponent } from './samplify-mappings/samplify-mappings.component';
import { EnableDecipherModalComponent } from './config-settings/enable-decipher-modal/enable-decipher-modal.component';
import { EnableNightcrawlerModalComponent } from './config-settings/enable-nightcrawler-modal/enable-nightcrawler-modal.component';
import { RnCredentialsModalComponent } from './config-settings/rn-credentials-modal/rn-credentials-modal.component';
import { CrmIdComponent } from './crm-id/crm-id.component';
import { InputRestrictionModule } from '../shared/ui/input-restriction/input-restriction.module';
import { ProgrammaticDirectSettingsComponent } from './programmatic-direct-settings/programmatic-direct-settings.component';
import { CoreMappingsComponent } from './core-mappings/core-mappings.component';
import { TrafficDensityFileComponent } from './traffic-density-file/traffic-density-file.component';
import { InputDecimalRestrictionModule } from '../shared/ui/input-decimal-restriction/input-decimal-restriction.module';
import { DataQualitySettingsComponent } from './data-quality-settings/data-quality-settings.component';
import { SafeHtmlPipeModule } from '../shared/pipes/safe-html-pipe-moduel';
import { PsidUploadConfirmationModalComponent } from './data-quality-settings/psid-upload-confirmation-modal/psid-upload-confirmation-modal.component';
import { PurepriceSettingsComponent } from './pureprice-settings/pureprice-settings.component';
import { BuyerRateCardComponent } from './buyer-rate-card/buyer-rate-card.component';
import { UserGuard } from '../auth/user.guard';
import { USER_TYPES } from '../operator/manage-companies/manage-companies-form/constant';
import { OeQuestionUploadConfirmationModalComponent } from './data-quality-settings/oe-question-upload-confirmation-modal/oe-question-upload-confirmation-modal.component';
import { AccordionModule } from '@purespectrum1/ui/accordion';
import { ConfigSettingsFilterPipe } from './config-settings/config-settings-pipe';

@NgModule({
  declarations: [
    OperatorSettingsComponent,
    CurrencySettingsComponent,
    MarginSettingsComponent,
    ConfigSettingsComponent,
    GlobalSettingsComponent,
    IPSOSMappingsComponent,
    ExternalBuyerMappingsComponent,
    ConfigSettingsComponent,
    SupplierSettingsComponent,
    ManageFeasibilityComponent,
    GenpopSettingsComponent,
    ManageCensusComponent,
    GenpopFileuploadModalComponent,
    GenpopSettingsPipe,
    SamplifyMappingsComponent,
    EnableDecipherModalComponent,
    EnableNightcrawlerModalComponent,
    RnCredentialsModalComponent,
    CrmIdComponent,
    ProgrammaticDirectSettingsComponent,
    CoreMappingsComponent,
    TrafficDensityFileComponent,
    DataQualitySettingsComponent,
    PsidUploadConfirmationModalComponent,
    PurepriceSettingsComponent,
    BuyerRateCardComponent,
    OeQuestionUploadConfirmationModalComponent,
    ConfigSettingsFilterPipe,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'operator-settings',
        canActivate: [AuthGuard, UserGuard],
        data: { roles: [USER_TYPES.OPERATOR] },
        children: [
          {
            path: '',
            component: OperatorSettingsComponent,
          },
        ],
      },
    ]),
    TabsModule,
    ButtonModule,
    CapsuleRadioButtonModule,
    NgSelectModule,
    SelectDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    SliderModule,
    ModalModule,
    InputRestrictionModule,
    InputDecimalRestrictionModule,
    SafeHtmlPipeModule,
    AccordionModule,
  ],
})
export class OperatorSettingsModule {}
