import { Injectable } from '@angular/core';
import { CPI } from 'src/app/layout/header/cpi/cpi.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  SurveyTopBarData,
  FeasibilityData,
  CPIData,
  SuppliersSelected,
  UpdateSupplierPayloadInterface,
  ProcessingData,
  SurveyData,
} from './survey-meta-data.interface';
import {
  BuyerSurveyResponse,
  GetSupplierBlendInterface,
} from '../buyer-api/survey.interface';
import { IPdsEnable } from '../supplier-api/supplier-api.interface';

@Injectable({
  providedIn: 'root',
})
export class SurveyMetaDataService {
  private readonly _defaultSurveyMetaData: SurveyTopBarData = {
    surveyId: 0,
    surveyStatus: 0,
    surveyLastSaved: 0,
    surveyFieldTime: 0,
    totalClicks: 0,
    expected_loi: 0,
    expected_ir: 0,
    incl_excl: 0,
  };
  private _surveyMetaData$ = new BehaviorSubject<SurveyTopBarData>(
    this._defaultSurveyMetaData
  );

  private readonly _defaultSurveyData = {
    surveyId: 0,
    mcUniqueId: '',
    activeTrafficChannel: false,
  };
  private _surveyData$ = new BehaviorSubject<SurveyData>(
    this._defaultSurveyData
  );

  private _surveyFeasibilityMetaData$ = new BehaviorSubject<FeasibilityData>({
    loading: false,
  });

  private _surveyCPIMetaData$ = new BehaviorSubject<CPIData>({
    loading: false,
  });

  private _surveySuppliersSelectionMetaData$ =
    new BehaviorSubject<SuppliersSelected>({});
  private _quotoMapperValidateData$ = new BehaviorSubject<{
    charCountExceedQuotas: string[];
    duplicatedBuyerQuotaIds: string[];
  }>({
    charCountExceedQuotas: [],
    duplicatedBuyerQuotaIds: [],
  });
  private _choosedSuppliersMetaData$ =
    new BehaviorSubject<UpdateSupplierPayloadInterface>(
      {} as UpdateSupplierPayloadInterface
    );
  private _supplierOverrideValue$ = new BehaviorSubject<boolean>(false);
  private _processingSaveSurveyMetaData$ = new Subject();
  private _processingSurveyMetaData$ = new BehaviorSubject<ProcessingData>({});
  private _isFormDirty$ = new BehaviorSubject<boolean>(false);
  private _cpiDropdownMetaData$ = new BehaviorSubject<CPI[]>([]);
  private _supplierBlendData$ = new BehaviorSubject<
    GetSupplierBlendInterface[]
  >([]);
  private _buyerSurveyData$ = new BehaviorSubject<BuyerSurveyResponse>(
    {} as BuyerSurveyResponse
  );
  private _pdsEnabledData$ = new BehaviorSubject<IPdsEnable>({
    enabled: false,
    buyer_name: null,
  });
  private _modularSurveyData$ = new BehaviorSubject<boolean>(false);
  private _priceRecomendationCPI$ = new BehaviorSubject<number>(0);

  constructor() {}

  /**
   * Revert the survey meta data stores to their default state so we don't display previous survey info
   */
  clearAll() {
    this._surveyMetaData$.next(this._defaultSurveyMetaData);
    this._surveyFeasibilityMetaData$.next({ loading: false });
    this._surveyCPIMetaData$.next({ loading: false });
    this._surveyData$.next(this._defaultSurveyData);
  }

  getSurveyHeaderData(): Observable<SurveyTopBarData> {
    return this._surveyMetaData$.asObservable();
  }

  sendSurveyHeaderData(topBarFields: SurveyTopBarData) {
    this._surveyMetaData$.next(topBarFields);
  }

  getFeasibilityData(): Observable<FeasibilityData> {
    return this._surveyFeasibilityMetaData$.asObservable();
  }

  sendFeasibilityLoading() {
    this._surveyFeasibilityMetaData$.next({
      loading: true,
    });
  }

  sendFeasibilityData(feasibilityData: FeasibilityData) {
    this._surveyFeasibilityMetaData$.next(feasibilityData);
  }

  getCPIData(): Observable<CPIData> {
    return this._surveyCPIMetaData$.asObservable();
  }

  sendCPILoading() {
    this._surveyCPIMetaData$.next({ loading: true });
  }

  sendCPIData(cpiData: CPIData) {
    this._surveyCPIMetaData$.next(cpiData);
  }

  getSuppliersSelected(): Observable<SuppliersSelected> {
    return this._surveySuppliersSelectionMetaData$.asObservable();
  }

  sendSuppliersSelected(suppliersSelected: SuppliersSelected) {
    this._surveySuppliersSelectionMetaData$.next(suppliersSelected);
  }

  setQuotaMapperValidateData(payload: {
    charCountExceedQuotas: string[];
    duplicatedBuyerQuotaIds: string[];
  }) {
    this._quotoMapperValidateData$.next(payload);
  }

  getQuotaMapperValidateData() {
    return this._quotoMapperValidateData$.asObservable();
  }

  setSupplierListPayload(supplier: UpdateSupplierPayloadInterface) {
    this._choosedSuppliersMetaData$.next(supplier);
  }

  getSupplierListPayload(): Observable<UpdateSupplierPayloadInterface> {
    return this._choosedSuppliersMetaData$.asObservable();
  }

  setSupplierOverrideValue(newValueToOverrideSupplier: boolean) {
    this._supplierOverrideValue$.next(newValueToOverrideSupplier);
  }
  getSupplierOverrideValue() {
    return this._supplierOverrideValue$.asObservable();
  }
  setSurveyData(surveyData: SurveyData) {
    this._surveyData$.next(surveyData);
  }
  getSurveyData(): Observable<SurveyData> {
    return this._surveyData$.asObservable();
  }

  setSurveyProcessing(processing: ProcessingData) {
    this._processingSurveyMetaData$.next(processing);
  }

  getSurveyProcessing(): Observable<ProcessingData> {
    return this._processingSurveyMetaData$.asObservable();
  }

  sendCPIDropdownData(cpiData: CPI[]) {
    this._cpiDropdownMetaData$.next(cpiData);
  }

  getCPIDropdownData(): Observable<CPI[]> {
    return this._cpiDropdownMetaData$.asObservable();
  }

  sendSupplierBlendData(data: GetSupplierBlendInterface[]) {
    this._supplierBlendData$.next(data);
  }

  getSupplierBlendData(): Observable<GetSupplierBlendInterface[]> {
    return this._supplierBlendData$.asObservable();
  }

  sendBuyerSurveyData(data: BuyerSurveyResponse) {
    this._buyerSurveyData$.next(data);
  }

  getBuyerSurveyData(): Observable<BuyerSurveyResponse> {
    return this._buyerSurveyData$.asObservable();
  }

  sendPdsEnabledData(data: IPdsEnable) {
    this._pdsEnabledData$.next(data);
  }

  getPdsEnabledData(): Observable<IPdsEnable> {
    return this._pdsEnabledData$.asObservable();
  }

  listenSaveSurvey(): Observable<unknown> {
    return this._processingSaveSurveyMetaData$.asObservable();
  }

  saveSurvey() {
    this._processingSaveSurveyMetaData$.next();
  }

  setIsFormDirty(flag: boolean) {
    this._isFormDirty$.next(flag);
  }

  getisFormDirty() {
    return this._isFormDirty$.asObservable();
  }

  sendModularSurveyData(isModularSurvey: boolean) {
    this._modularSurveyData$.next(isModularSurvey);
  }

  getModularSurveyData(): Observable<boolean> {
    return this._modularSurveyData$.asObservable();
  }
}
