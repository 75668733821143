import {
  Router,
  ActivatedRoute,
  NavigationBehaviorOptions,
} from '@angular/router';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MarketplaceService } from '@purespectrum1/ui/marketplace';
import { AuthService } from '../../auth/auth.service';
import { SURVEY_STATUS } from '../../constants/survey-status';
import {
  BuyerSurveyResponse,
  McSVPEachSurvey,
} from '../../shared/services/buyer-api/survey.interface';

@Component({
  selector: 'ps-edit-survey-footer',
  templateUrl: './edit-survey-footer.component.html',
  styleUrls: ['./edit-survey-footer.component.css'],
})
export class EditSurveyFooterComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  public userType: string = '';
  public isServiceBuyerLoggedIn: boolean = false;
  public surveyStatus = SURVEY_STATUS;
  @Input() surveyId!: number | undefined;
  @Input() surveyChannelType!: string | undefined;
  @Input() isQbpChildSurvey: boolean = false;
  @Input() isRecontact: boolean = false;
  @Input() st: number | undefined;
  @Input() survey: BuyerSurveyResponse = {} as BuyerSurveyResponse;
  @Input() selectedSvpCountry: McSVPEachSurvey = {} as McSVPEachSurvey;

  constructor(
    private _route: ActivatedRoute,
    private _marketplaceService: MarketplaceService,
    private _router: Router,
    private _auth: AuthService
  ) {
    this.userType = this._auth.userType;
  }

  ngOnInit() {
    this._subscriptions.add(
      this._route.params.subscribe((params) => {
        this.surveyId = params.id;
      })
    );
    this._subscriptions.add(
      this._auth.loggedInAsServiceBuyer$.subscribe((isLoggedIn) => {
        this.isServiceBuyerLoggedIn = isLoggedIn;
      })
    );
  }

  redirectToEditPage() {
    this._marketplaceService.resetMultiCountryData();
    if (this.isRecontact) {
      this._router.navigate(['recontact-survey', this.surveyId]);
    } else {
      this._router.navigate(
        ['surveys', this._getSurveyId(), 'edit'],
        this._prepareNavigationExtras()
      );
    }
  }

  redirectToLaunchPage() {
    this._marketplaceService.resetMultiCountryData();
    this._router.navigate(
      ['launch-survey', this._getSurveyId()],
      this._prepareNavigationExtras()
    );
  }

  redirectToSupplierPage() {
    this._router.navigate(
      ['surveys', this._getSurveyId(), 'edit', 'suppliers'],
      this._prepareNavigationExtras()
    );
  }

  private _getSurveyId() {
    if (
      this.survey?.mc?.SVP &&
      !this.survey.mc?.parent &&
      this.survey.ps_project_id
    ) {
      return this.survey.ps_project_id;
    }
    return this.surveyId;
  }

  private _prepareNavigationExtras(): NavigationBehaviorOptions {
    if (this._isMcSvpChildSurvey()) {
      return {
        state: {
          svp_child_id: this.selectedSvpCountry.surveyId,
        },
      };
    }

    return {};
  }

  private _isMcSvpChildSurvey(): boolean {
    return !!this.selectedSvpCountry?.surveyId;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
