import { Injectable } from '@angular/core';
import { SurveyStatus } from '../../../utils/survey-status';
import {
  BuyerSurveyResponse,
  SurveyUpdate,
} from '../buyer-api/survey.interface';
import { BULK_STATUS_CHANGE_MODAL } from '../../../constants/modal-constants';

@Injectable({
  providedIn: 'root',
})
export class BulkInvoiceHelperService {
  constructor() {}

  public decideSurveysToChangeStatusOf(
    status: SurveyStatus,
    action: string,
    notInvoicedSurveysWithSamePo: BuyerSurveyResponse[],
    selectedSurveys: number[]
  ): [boolean, boolean, SurveyUpdate[], SurveyUpdate[]] {
    if (
      [
        BULK_STATUS_CHANGE_MODAL.NO_BULK_INVOICING_ACTION,
        BULK_STATUS_CHANGE_MODAL.BUTTON.ONLY_THIS.ACTION,
      ].includes(action)
    ) {
      return [
        true,
        action !== BULK_STATUS_CHANGE_MODAL.NO_BULK_INVOICING_ACTION,
        [],
        this._prepareSurveyStatusChangePayload(status, selectedSurveys),
      ];
    }
    if (action === BULK_STATUS_CHANGE_MODAL.BUTTON.THIS_AND_ALL_LISTED.ACTION) {
      const liveOrPausedSurveyIdsWithSamePo = notInvoicedSurveysWithSamePo
        .filter((s) =>
          [SurveyStatus.Live, SurveyStatus.Paused].includes(s.ps_survey_status)
        )
        .map((s) => s.ps_survey_id);
      const notInvoicedSurveyIdsWithSamePo = notInvoicedSurveysWithSamePo.map(
        (s) => s.ps_survey_id
      );
      return [
        true,
        true,
        this._prepareSurveyStatusChangePayload(
          SurveyStatus.Closed,
          liveOrPausedSurveyIdsWithSamePo
        ),
        this._prepareSurveyStatusChangePayload(status, [
          ...notInvoicedSurveyIdsWithSamePo,
          ...selectedSurveys,
        ]),
      ];
    }
    return [false, true, [], []];
  }

  private _prepareSurveyStatusChangePayload(
    status: SurveyStatus,
    surveyIds: (number | undefined)[]
  ) {
    return [
      ...new Set(
        surveyIds
          .filter((surveyId) => !!surveyId)
          .map((surveyId) => {
            return <SurveyUpdate>{
              surveyId,
              status,
            };
          })
      ),
    ];
  }

  public fetchNotInvoicedSortedSurveyIdsWithSamePo(
    notInvoicedSurveysWithSamePo: BuyerSurveyResponse[]
  ) {
    return notInvoicedSurveysWithSamePo
      .map((s) => Number(s.ps_survey_id))
      .sort((a, b) => a - b);
  }
}
