import { SurveyListing } from '../../../shared/services/buyer-api/survey.interface';
import { SurveyStatus } from '../../../utils/survey-status';
import { EditableSurvey } from '../types';
import { BulkViewColumn, BulkViewColumnType } from './types';
import { CurrencyExchange } from '../../../shared/types/currency-service-interface';
import { LocaleToUSDConversion } from '../../../utils/currency-conversion';
import { StringTruncator } from '../../../utils/string-truncate';
import { BulkEditConstants } from '../constants';

export class BulkViewSimpleColumn implements BulkViewColumn {
  constructor(
    private readonly _key: keyof SurveyListing,
    private readonly _title: string
  ) {}

  public header(): string {
    return this._title;
  }

  public content(survey: SurveyListing): string {
    return survey[this._key] as string;
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return false;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(survey: SurveyListing): string {
    return this._key === 'margin' && survey?.is_enable_margin_override
      ? '%'
      : '';
  }
}

export class BulkViewCurrencyColumn implements BulkViewColumn {
  constructor(
    private readonly _key: keyof SurveyListing,
    private readonly _title: string
  ) {}

  public header(): string {
    return this._title;
  }

  public content(survey: SurveyListing): string {
    return survey[this._key] === 'N/A'
      ? 'N/A'
      : `${Number(survey[this._key]).toFixed(2)}`;
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return false;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(survey: SurveyListing): string {
    return survey[this._key] === 'N/A' ? '' : `${survey.currencySymbol}`;
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewCountryLanguageColumn implements BulkViewColumn {
  public header(): string {
    return 'Country and Language';
  }

  public content(survey: SurveyListing): string {
    return survey.countryLang as string;
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return false;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewMarginColumn implements BulkViewColumn {
  public header(): string {
    return 'Current Margin';
  }

  public content(survey: SurveyListing): string {
    if (!survey.is_enable_margin_override) {
      return 'N/A';
    }

    return `${survey.actualMargin.toFixed(2)}`;
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return false;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(survey: SurveyListing): string {
    if (!survey.is_enable_margin_override) {
      return '';
    }

    return '%';
  }
}

export class BulkViewSupplierCpiColumn implements BulkViewColumn {
  constructor(
    private readonly _buyerCpi: number,
    private readonly _currencyExchange = <CurrencyExchange>{}
  ) {}
  public header(): string {
    return 'New Supplier CPI';
  }

  public content(survey: SurveyListing): string {
    if (!survey.is_enable_margin_override) {
      return 'N/A';
    }

    const buyerCpi = this._buyerCpi || 0;
    const margin = survey.actualMargin || 0;

    const scpi = (buyerCpi * (100 - margin)) / 100;
    return new LocaleToUSDConversion(scpi, this._currencyExchange)
      .convert()
      .toFixed(2);
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return false;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(survey: SurveyListing): string {
    if (!survey.is_enable_margin_override) {
      return '';
    }

    return `${survey.currencySymbol}`;
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewEditableCpiColumn implements BulkViewColumn {
  private readonly _key: keyof SurveyListing = 'aCpi';
  constructor() {}

  public header(): string {
    return 'New CPI';
  }

  public content(survey: SurveyListing): string {
    return survey[this._key] as string;
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return true;
  }

  public type(): BulkViewColumnType {
    return 'cpi';
  }

  public prefix(survey: SurveyListing): string {
    return `${survey.currencySymbol}`;
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewEditableMarginColumn implements BulkViewColumn {
  private readonly _key: keyof SurveyListing = 'margin';
  constructor() {}

  public header(): string {
    return 'New Margin';
  }

  public content(survey: SurveyListing): string {
    return survey[this._key] as string;
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return true;
  }

  public type(): BulkViewColumnType {
    return 'margin';
  }

  public prefix(survey: SurveyListing): string {
    return ``;
  }

  public suffix(survey: SurveyListing): string {
    return '%';
  }
}

export class BulkViewFieldedVsGoalColumn implements BulkViewColumn {
  public header(): string {
    return 'Fielded vs Goal';
  }

  public content(survey: SurveyListing): string {
    return `${survey.fielded} | ${survey.goal}`;
  }

  public footer(surveys: EditableSurvey[]): string {
    const fielded = surveys.reduce(
      (acc, { survey }) => acc + survey.fielded,
      0
    );
    const goal = surveys.reduce((acc, { survey }) => acc + survey.goal, 0);
    return `Total: ${fielded} | ${goal}`;
  }

  public editable(): boolean {
    return true;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewCompletesLeftColumn implements BulkViewColumn {
  public header(): string {
    return 'Completes Left';
  }

  public content(survey: SurveyListing): string {
    return `${survey.goal - survey.fielded}`;
  }

  public footer(surveys: EditableSurvey[]): string {
    const completesLeft = surveys.reduce(
      (acc, { survey }) => acc + (survey.goal - survey.fielded),
      0
    );
    return `${completesLeft}`;
  }

  public editable(): boolean {
    return true;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewStatusColumn implements BulkViewColumn {
  constructor(private readonly _editable: boolean = false) {}
  public header(): string {
    return this._editable ? 'New Status' : 'Current Status';
  }

  public content(survey: SurveyListing): string {
    return SurveyStatus[survey.status];
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return this._editable;
  }

  public type(): BulkViewColumnType {
    return 'status';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewCostColumn implements BulkViewColumn {
  public header(): string {
    return 'Cost';
  }

  public content(survey: SurveyListing): string {
    return (survey.current_cost || 0).toFixed(2);
  }

  public footer(surveys: EditableSurvey[]): string {
    const cost = surveys.reduce(
      (acc, { survey }) => acc + (survey.current_cost || 0),
      0
    );

    return `Total: ${cost.toFixed(2)}`;
  }

  public editable(): boolean {
    return false;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewManagersColumn implements BulkViewColumn {
  constructor(private _type: 'po' | 'pm', private readonly _disclaimer = '') {}

  public header(): string {
    return this._type === 'po'
      ? `New Operator ${this._disclaimer}Manager`
      : `New ${this._disclaimer}Project Manager`;
  }

  public content(_: SurveyListing): string {
    return '';
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return true;
  }

  public type(): BulkViewColumnType {
    return this._type;
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewEditableBillingColumn implements BulkViewColumn {
  public header(): string {
    return 'New Billing Number';
  }

  public content(_: SurveyListing): string {
    return '';
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return true;
  }

  public type(): BulkViewColumnType {
    return 'billing';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewBillingColumn implements BulkViewColumn {
  public header(): string {
    return 'Current Billing Number';
  }

  public content(survey: SurveyListing): string {
    const billingId = survey.billing_id || '-';
    return new StringTruncator(
      billingId,
      BulkEditConstants.BILLING_ID_MAX_LENGTH
    ).truncate();
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return false;
  }

  public type(): BulkViewColumnType {
    return 'text';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewInfoColumn implements BulkViewColumn {
  public header(): string {
    return 'Info';
  }

  public content(_: SurveyListing): string {
    return '';
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return false;
  }

  public type(): BulkViewColumnType {
    return 'info';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}

export class BulkViewBillingActionColumn implements BulkViewColumn {
  constructor() {}

  public header(): string {
    return 'Current Billing Number';
  }

  public content(
    survey: SurveyListing,
    mode: 'edit' | 'view' = 'view'
  ): string {
    if (mode === 'edit') {
      return survey.billing_id || '';
    }

    const billingId = survey.billing_id || '-';
    const billingTruncator = new StringTruncator(
      billingId,
      BulkEditConstants.BILLING_ID_MAX_LENGTH
    );
    return billingTruncator.truncate();
  }

  public footer(_: EditableSurvey[]): string {
    return '';
  }

  public editable(): boolean {
    return true;
  }

  public type(): BulkViewColumnType {
    return 'billing-action';
  }

  public prefix(_: SurveyListing): string {
    return '';
  }

  public suffix(_: SurveyListing): string {
    return '';
  }
}
