import { SurveyListing } from '../../../shared/services/buyer-api/survey.interface';
import {
  BulkEditPayloadValue,
  EditableSurveyEdited,
  EditableSurveyError,
  EditableSurveyPending,
  ErrorPosition,
  SurveyModeDefault,
  SurveyModePending,
  ErrorType,
} from '../../bulk-edit/types';

export interface ComposedState {
  pending(): EditableSurveyPending;
  edited(): EditableSurveyEdited;
  error(
    msg: string,
    position?: ErrorPosition,
    type?: ErrorType
  ): EditableSurveyError;
  marginError(
    msg: string,
    position?: ErrorPosition,
    type?: ErrorType
  ): EditableSurveyError;
}

export class EditableSurveyStateComposer implements ComposedState {
  constructor(
    private readonly _survey: SurveyListing,
    private readonly _editValue: BulkEditPayloadValue
  ) {}

  public pending(
    surveyMode: SurveyModePending = this._defaultSurveyMode()
  ): EditableSurveyPending {
    return {
      value: this._editValue,
      survey: this._survey,
      id: this._survey.surveyId,
      status: 'pending',
      surveyMode: surveyMode,
    };
  }

  public edited(): EditableSurveyEdited {
    return {
      value: this._editValue,
      survey: this._survey,
      id: this._survey.surveyId,
      status: 'edited',
    };
  }

  public error(
    msg: string,
    position: ErrorPosition = 'info-column',
    type: ErrorType = 'error'
  ): EditableSurveyError {
    return {
      value: this._editValue,
      survey: this._survey,
      id: this._survey.surveyId,
      status: type,
      surveyMode: this._defaultSurveyMode(),
      error: msg,
      position,
    };
  }

  private _defaultSurveyMode(): SurveyModeDefault {
    return {
      billing_id: 'view',
    };
  }

  public marginError(
    msg: string,
    position: ErrorPosition = 'info-column',
    type: ErrorType = 'warning'
  ) {
    const error = this.error(msg, position, type);
    error.isBelowAverageMargin = true;
    return error;
  }
}
