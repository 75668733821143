import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BulkEditEvent, BulkEditOption, CombinedReport } from './types';
import {
  PatchSurveyInterface,
  SurveyListing,
} from '../../shared/services/buyer-api/survey.interface';
import { ModalService, ToasterService } from '@purespectrum1/ui';
import { BulkOptionConfirmationComponent } from '../bulk-option-confirmation/bulk-option-confirmation.component';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';

import { ProjectManagers } from '../../operator/user-service/user.interface';
import { AuthService } from '../../auth/auth.service';
import { SurveyData } from '@purespectrum1/ui/survey-top-view/shared/survey.interface';
import { DashboardSelectedSurveys } from '../domain/dasboard-survey-selected';
import {
  CardsFormatter,
  CustomFormatterState,
  DefaultFormatterState,
} from './domain/cards-formatter';
import { DEFAULT_SELECTED_SURVEY } from '../dashboard-constants';
import { BulkEditViewFactory } from './domain/bulkd-edit-view-factory';
import { BulkEditPayloadFormat } from './domain/bulk-payload-formatter';
import { BulkMenuFilter } from './domain/bulk-menu-filter';
import { ConfirmationModalComponent } from '../../shared/ui/confirmation-modal/confirmation-modal.component';
import { Constants as CreateSurveyConstants } from '../../create-surveys/create-survey.constants';
import { DEFAULT_CURRENCY_UNIT } from '../../constants/currency-units';
import { LayoutConstants } from '../../layout/layout-constants';
import { notifyMessage } from '../../constants/notify-message';
import { OptionsConstants } from './constants';
import { WARNING_MESSAGE } from './../../constants/modal-constants';
import { BulkEditContainerComponent } from './bulk-edit-container/bulk-edit-container.component';
import { DashboardTab } from '../domain/dashboard-tab';

export interface EditEvent {
  surveyId: number;
  payload: PatchSurveyInterface[];
}

@Component({
  selector: 'ps-bulk-edit',
  templateUrl: './bulk-edit.component.html',
  styleUrls: ['bulk-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkEditComponent implements OnInit, OnDestroy {
  public get cardsFormatter(): CardsFormatter {
    if (!this._cardsFormatter) {
      this._cardsFormatter = new CardsFormatter(new CustomFormatterState());
    }
    return this._cardsFormatter;
  }

  @Input()
  public set selected(state: DashboardSelectedSurveys) {
    this.handleSelected(state);
  }

  @Input()
  public total: number = 0;

  @Input()
  public tab!: DashboardTab;

  @Output()
  public edit: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public report = new EventEmitter<CombinedReport>();

  @ViewChild(BulkEditContainerComponent)
  bulkEditContainerComponent!: BulkEditContainerComponent;

  public state: DashboardSelectedSurveys;
  public userType = this._auth.userType;
  public dropdownOptions: BulkEditOption[] = OptionsConstants.OPTIONS;
  public show: Boolean = false;
  public selectedOption?: BulkEditOption;
  public defaultOption!: BulkEditOption;
  public projectManagers$ = new BehaviorSubject<ProjectManagers[]>([]);
  public statsFormatted: SurveyData = new DefaultFormatterState().format(
    DEFAULT_SELECTED_SURVEY
  );

  public dropdownOptions$!: Observable<BulkEditOption[]>;

  private _confirmationCommand$ = new Subject<BulkEditEvent>();
  private _subscriptions = new Subscription();
  private _isServiceBuyer = false;
  private _cardsFormatter: CardsFormatter | null = null;

  constructor(
    private _modal: ModalService,
    private _auth: AuthService,
    private _toastr: ToasterService
  ) {
    this.state = DashboardSelectedSurveys.empty();
  }

  public ngOnInit(): void {
    this.show = !!this.state.selected();
    const confirmationSubscription = this._confirmationCommand$
      .pipe(
        filter(() => !!this.selectedOption),
        switchMap((event) => this._validations(event)),
        filter((event) => !!event.data.changeValue),
        switchMap((event) => {
          const payloads = new BulkEditPayloadFormat(
            event.data.changeValue,
            this.selectedOption!.type,
            this.state.selected()
          ).payloads();

          const view = new BulkEditViewFactory(
            this.selectedOption!,
            event.data.changeValue,
            this._auth.buyerConfig!,
            this._isServiceBuyer,
            this._auth.currencyExchange
          ).create();

          return this._modal
            .open(BulkOptionConfirmationComponent, {
              data: {
                surveys: this.state.selected(),
                type: this.selectedOption!.type,
                value: event.data.changeValue,
                selected: event.data.changeValue,
                managers: event.managers,
                view,
                payloads,
              },
            })
            .onClose$.pipe(filter((updated: boolean) => updated));
        })
      )
      .subscribe((editPayloads) => {
        if (editPayloads) {
          this.selectedOption = undefined;
        }

        this.edit.emit(editPayloads);
      });

    this._subscriptions.add(confirmationSubscription);

    this.dropdownOptions$ = this._auth.loggedInAsServiceBuyer$.pipe(
      tap((isServiceBuyer) => {
        this._isServiceBuyer = isServiceBuyer;
      }),
      map((isServiceBuyer) => [
        ...new BulkMenuFilter(
          this._auth.buyerConfig!,
          this._auth.userType,
          isServiceBuyer,
          this.tab.title
        ).options(),
      ])
    );
  }

  public ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  public onSelectedOption(option: BulkEditOption): void {
    this.selectedOption = option;
  }

  public onConfirmation(event: BulkEditEvent): void {
    this._confirmationCommand$.next(event);
  }

  public onReport(report: CombinedReport) {
    this.report.emit(report);
  }

  public onSelectedSettings() {
    this.show = !this.show;
  }

  public handleSelected(state: DashboardSelectedSurveys): void {
    if (!state.some()) {
      this._clearSelectedOption();
    }

    this._updateStatsFormatted(state);
    this.state = state;
  }

  private _validations(event: BulkEditEvent): Observable<BulkEditEvent> {
    if (this.selectedOption?.type === 'cpi') {
      return this._validateCpi(event);
    }

    if (this.selectedOption?.type === 'margin') {
      return this._validateMargin(event);
    }
    return of(event);
  }

  private _validateMargin(event: BulkEditEvent): Observable<BulkEditEvent> {
    return this._validateMarginBelowAverage(event).pipe(
      switchMap(() => this._validateMinMaxMargin(event)),
      map(() => event)
    );
  }

  private _validateMinMaxMargin(event: BulkEditEvent): Observable<boolean> {
    return of(event).pipe(
      switchMap((e) => {
        if (
          Number(e.data.changeValue) < CreateSurveyConstants.MIN_MARGIN ||
          Number(e.data.changeValue) > CreateSurveyConstants.MAX_MARGIN
        ) {
          this._toastr.error(
            notifyMessage.errorMessage.MARGIN_MIN_MAX_VALUE_ERROR
          );
          return of(false);
        }
        return of(true);
      }),
      filter((proceed) => proceed)
    );
  }

  private _validateMarginBelowAverage(
    event: BulkEditEvent
  ): Observable<boolean> {
    if (
      Number(event.data.changeValue) >=
      CreateSurveyConstants.AVERAGE_VARIABLE_MARGIN
    ) {
      return of(true);
    }
    return this._modal
      .open(ConfirmationModalComponent, {
        data: {
          text: `${WARNING_MESSAGE.MARGIN_WARNING_TEXT} \n\n ${WARNING_MESSAGE.MARGIN_WARNING_RECOMMEND_TEXT}`,
          cancelButtonLabel: 'Cancel',
          confirmButtonLabel: 'Confirm',
        },
        maxWidth: '30%',
      })
      .onClose$.pipe(
        tap(() => this.bulkEditContainerComponent.setFocus()),
        filter((msg) => msg === 'ok')
      );
  }

  private _validateCpi(event: BulkEditEvent): Observable<BulkEditEvent> {
    return this._validateCpiEmptyValue(event).pipe(
      switchMap(() => this._validateHighCpi(event)),
      map(() => event)
    );
  }

  private _validateCpiEmptyValue(event: BulkEditEvent): Observable<boolean> {
    return of(event).pipe(
      switchMap((e) => {
        if (e.data.changeValue && Number(e.data.changeValue) !== 0) {
          return of(true);
        }
        this._toastr.error(
          notifyMessage.errorMessage.BULK_EDIT.EDIT_CPI.EMPTY_VALUE
        );
        return of(false);
      }),
      filter((proceed) => proceed)
    );
  }

  private _validateHighCpi(event: BulkEditEvent): Observable<boolean> {
    if (Number(event.data.changeValue) <= LayoutConstants.MAX_CPI_THRESHOLD) {
      return of(true);
    }
    return this._modal
      .open(ConfirmationModalComponent, {
        data: {
          innerHtml: CreateSurveyConstants.CONFIRM_MODAL_TEXT.HIGH_CPI.replace(
            '<CPI>',
            `${parseFloat(event.data.changeValue.toString())}`
          ).replace(
            '<CURRENCY_SYMBOL>',
            `${this._currencySymbol(this.state.selected())}`
          ),
          confirmButtonLabel: 'Confirm',
        },
        maxWidth: '25%',
      })
      .onClose$.pipe(filter((msg) => msg === 'ok'));
  }

  private _currencySymbol(surveys: SurveyListing[]): string {
    return (
      surveys.find((s) => s.currencySymbol)?.currencySymbol ||
      DEFAULT_CURRENCY_UNIT
    );
  }

  private _clearSelectedOption(): void {
    this.selectedOption = undefined;
  }

  private _updateStatsFormatted(state: DashboardSelectedSurveys): void {
    const formatter = this.cardsFormatter;
    this.statsFormatted = formatter.format(state.stats());
  }
}
