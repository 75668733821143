import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from '@purespectrum1/ui/modal';

import { forkJoin, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

// Constants
import { Constants, WIDGETS } from '../dashboard-constants';
import { STATUS_BUTTONS } from '../../constants/status-buttons';
import { SURVEY_STATUS } from '../../constants/survey-status';
import { INVOICE_TYPE } from '../../constants/invoice-type';
import { SurveyStatus } from '../../utils/survey-status';
import { notifyMessage } from '../../constants/notify-message';

// Interfaces
import {
  DashboardChannel,
  DashboardPreference,
  SurveyListing,
  SurveyUpdate,
  Survey,
  TcResponse,
  GetSurveysQueryParams,
  BuyerSurveyResponse,
} from '../../shared/services/buyer-api/survey.interface';
import {
  BuyerProgressChannelV3,
  SurveyStatusChange,
} from '../../shared/services/socket/socket.interface';
import { SupplierSurveysResponse } from '../survey-service/supplier-survey.interface';

// Components
import { Tab } from '../types';
import { TosComponent } from '../tos/tos.component';
import { ChangeSurveyStatusModalComponent } from '../change-survey-status-modal/change-survey-status-modal.component';
import { DashboardPreferenceModalComponent } from '../dashboard-preference-modal/dashboard-preference-modal.component';

// Services
import { AuthService } from '../../auth/auth.service';
import { BuyerService } from '../../shared/services/buyer/buyer.service';
import { SocketService } from '../../shared/services/socket/socket.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { BuyerApiService } from '../../shared/services/buyer-api/survey.service';
import { SurveyPayloadService } from '../../create-surveys/survey-payload.service';
import { SupplierApiService } from '../../shared/services/supplier-api/supplier-api.service';
import { DashboardPreferenceService } from '../../shared/services/dashboard-preference/dashboard-preference.service';
import { SelectedWidget, Widget } from '../widget/widget';
import { TrafficChannelModalComponent } from '@purespectrum1/ui/marketplace';
import { CommonService } from '../../shared/services/common-service/common-service';
import { BulkSurveyStatusChangeModalComponent } from '../../shared/ui/bulk-survey-status-change-modal/bulk-survey-status-change-modal.component';
import { BULK_STATUS_CHANGE_MODAL } from '../../constants/modal-constants';
import { BulkInvoiceHelperService } from '../../shared/services/bulk-invoice/bulk-invoice-helper.service';
import { BillingService } from '../../shared/services/billing-service/billing.service';
import { DashboardViewPayload } from '../../operator/user-service/user.interface';
import { UserService } from '../../operator/user-service/user.service';
import { SurveysLength } from '../survey-table/domain/surveys-length';

@Component({
  selector: 'ps-survey-listing',
  templateUrl: './survey-listing.component.html',
  styleUrls: ['./survey-listing.component.css'],
})
export class SurveyListingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tab!: Tab;
  @Input() searchKey: string = '';
  @Input() projectManagerId?: number;
  @Input() buyerProjectManagerId?: number;
  @Output() readonly surveyStatusUpdate =
    new EventEmitter<SurveyStatusChange>();

  private _subscription: Subscription = new Subscription();
  public userType = this._auth.userType;
  public surveys: SurveyListing[] = [];
  public surveyStatus: { [key: number]: string } = SurveyStatus;
  private _page: number = 1;
  private _canLoadMore: boolean = true;
  public statusButtons = STATUS_BUTTONS;
  public SURVEY_STATUS = SURVEY_STATUS;
  private _userCompanyId = this._auth.user?.cmp;
  parseInt = parseInt;
  tableNextLoader: boolean = false;
  private _selectedBuyerId?: number;
  public RECONTACT_SURVEY_SAMPLE_TYPE = Constants.RECONTACT_SURVEY_SAMPLE_TYPE;
  public SURVEY_LISTING_TABLE_HEADERS = Constants.SURVEY_LISTING_TABLE_HEADERS;
  public isSupplier = false;
  public _isServiceBuyerLoggedIn: boolean = false;
  public enableTrafficChannel: boolean | undefined = false;
  public enabledDynamicDashboard: boolean = false;
  public canClose: boolean = true;
  public includeTestTransactions: boolean = false;
  public tableHeaders: DashboardPreference[] = [];
  private _fetchSurveys: boolean = true;

  public widgets: Widget[] = [];
  public selectedWidget = SelectedWidget.empty();

  constructor(
    private _toastr: ToasterService,
    private _auth: AuthService,
    private _buyerApiService: BuyerApiService,
    private _supplierApiService: SupplierApiService,
    private _modal: ModalService,
    private _route: Router,
    private _socketService: SocketService,
    private _deviceService: DeviceDetectorService,
    private _surveyPayloadService: SurveyPayloadService,
    private _buyerService: BuyerService,
    private _dashboardPreferenceService: DashboardPreferenceService,
    private _commonService: CommonService,
    private _bulkInvoiceHelperService: BulkInvoiceHelperService,
    private _billingService: BillingService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    if (this._auth.isLoggedInAsServiceBuyer()) {
      this._selectedBuyerId = this._auth.serviceBuyer!.id;
      this._isServiceBuyerLoggedIn = true;
    }
    this.getFilteredSurveys(this._page, this.searchKey);
    this._checkIfUserHasAcceptedTOS();
    this._socketSubscriptions();
    const authServiceSubscription =
      this._auth.loggedInAsServiceBuyer$.subscribe((isLoggedIn) => {
        // NOTE: this and first line if was necessary due the subscription ref is lost
        // each time we call another search
        if (isLoggedIn) {
          this._isServiceBuyerLoggedIn = true;
        }
        if (this._selectedBuyerId !== this._auth.serviceBuyer?.id) {
          this._selectedBuyerId = this._auth.serviceBuyer?.id;
          this.surveys = [];
          this.getSurvey(this._page, this.searchKey);
        }
      });
    this._subscription.add(authServiceSubscription);
    this.isSupplier = this._auth.user?.supplierAcssLvls !== 'none';
    this.enableTrafficChannel = this._auth.buyerConfig?.enableTrafficChannels;
    this.enabledDynamicDashboard =
      !!this._auth.buyerConfig?.enableDynamicDash ||
      this._auth.user?.operatorAcssLvls !== 'none';
    this._getUserDashboardPreferences();
  }

  ngOnChanges(value: SimpleChanges): void {
    if (value.hasOwnProperty('searchKey') && !value.searchKey.firstChange) {
      this.surveys = [];
      this._page = 1;
      this.getSurvey(this._page, this.searchKey);
    }

    if (value?.projectManagerId && !value?.projectManagerId.firstChange) {
      this.surveys = [];
      this._page = 1;
      this.getSurvey(this._page, this.searchKey);
    }
    if (
      value?.buyerProjectManagerId &&
      !value?.buyerProjectManagerId.firstChange
    ) {
      this.surveys = [];
      this._page = 1;
      this.getSurvey(this._page, this.searchKey);
    }
  }

  getFilteredSurveys(page: number, searchKey: string) {
    if (this.userType === 'supplier') {
      this.getSupplierSurveys(this.tab.status, page, searchKey);
    } else {
      this.tableNextLoader = true;
      this._getUserViewSelection(page, searchKey);
    }
  }

  private _getUserViewSelection(page: number, searchKey: string) {
    const userId = this._auth.user?.id;
    const getUserViewSub = this._userService
      .getUserViewSelection(userId)
      .subscribe((response: DashboardViewPayload) => {
        const { projectManagerObject } =
          this._userService.setSurveyFilterOptions(
            response,
            this._auth.user?.id || 0
          );
        this.buyerProjectManagerId = projectManagerObject.buyer;
        this.projectManagerId = projectManagerObject.serviceOperator;
        this.getBuyerSurveys(this.tab.status, page, searchKey);
      });
    this._subscription.add(getUserViewSub);
  }

  public onWidgetPressed(widget: Widget, survey: SurveyListing) {
    this.selectedWidget = this.selectedWidget.select(widget, survey);
  }

  private _socketSubscriptions() {
    this._subscription.add(
      this._socketService.buyerProgressChannelV3$.subscribe((data) =>
        this._updateBuyerInProgress(data)
      )
    );
    this._subscription.add(
      this._socketService.surveyStatusChange$.subscribe((data) =>
        this._updateSurveyStatus(data)
      )
    );
    this._subscription.add(
      this._socketService.dashboardChannel$.subscribe(
        (data: DashboardChannel) => {
          this._updateDashboardWithDataReceived(data);
        }
      )
    );
  }

  private _updateBuyerInProgress(data: BuyerProgressChannelV3) {
    const surveyIndex = this.surveys.findIndex(
      (survey) => survey.surveyId === data?.surveyId
    );
    if (surveyIndex !== -1 && data?.hasOwnProperty('actualBuyerInProgress')) {
      this.surveys[surveyIndex].inProgress = data.actualBuyerInProgress;
    }
  }

  private _updateSurveyStatus(data: SurveyStatusChange) {
    // send data to dashboard component to increase counters of newStatus survey tab
    // means if survey status is changed from Live to Pause then pause counter will be increased with this surveyStatusUpdate event emitter from dashboard component.
    if (Number(this.tab.status) === data?.oldStatus) {
      const surveyIndex = this.surveys.findIndex(
        (survey) => survey.surveyId === data?.surveyId
      );
      if (surveyIndex !== -1) {
        this.surveys.splice(surveyIndex, 1);
        this.tab.count > 0 && this.tab.count--;
        this.surveyStatusUpdate.emit(data);
      }
    } else if (this.tab.status === 'all' && data.newStatus) {
      const surveyIndex = this.surveys.findIndex(
        (survey) => survey.surveyId === data?.surveyId
      );
      if (surveyIndex !== -1) {
        this.surveys[surveyIndex].status = data.newStatus;
      }
    }
  }

  private _updateDashboardWithDataReceived(data: DashboardChannel) {
    const { supplierManagement, surveyManagement } = data;
    if (
      this.userType === 'supplier' &&
      this._userCompanyId === supplierManagement.supplier_id
    ) {
      this.surveys = this.surveys.map((srv) =>
        srv.surveyId === supplierManagement.survey_id
          ? {
              ...srv,
              fielded: supplierManagement.fielded,
            }
          : srv
      );
    } else {
      this.surveys = this.surveys.map((srv) =>
        srv.surveyId === surveyManagement.survey_id
          ? {
              ...srv,
              currentIR: `${surveyManagement.incidence_current}%`,
              averageCpi: surveyManagement.averageCpi,
              fielded: surveyManagement.fielded,
            }
          : srv
      );
    }
  }

  onScroll() {
    if (!this._fetchSurveys) {
      return; // stop multiple calls from happening using `infiniteScroll`
    }
    if (this._canLoadMore) {
      this._fetchSurveys = false;
      const page: number = this._page + 1;
      this.getSurvey(page, this.searchKey);
    }
  }

  getSurvey(page: number, searchKey: string) {
    if (this.userType === 'supplier') {
      this.getSupplierSurveys(this.tab.status, page, searchKey);
    } else {
      this.tableNextLoader = true;
      this.getBuyerSurveys(this.tab.status, page, searchKey);
    }
  }

  getBuyerSurveys(status: any, page: number, searchKey: string) {
    const defaultLimit = 20;
    const queryParams: GetSurveysQueryParams = {
      UI: 1,
      page,
      search: searchKey,
      status: status || SurveyStatus.Live,
      targeting: 0,
      limit: defaultLimit,
      buyerId: this._selectedBuyerId,
      projectManagerId: this.projectManagerId,
      buyerProjectManagerId: this.buyerProjectManagerId,
      isServiceBuyerRequest: this._isServiceBuyerLoggedIn,
      mobile: this._deviceService.isMobile(),
    };
    const buyerSurveys = this._buyerApiService
      .getSurveys(queryParams)
      .subscribe(
        (response) => {
          this.mapSurveys(response, page);
        },
        (error: any) => {
          this._fetchSurveys = true;
          this._toastr.error(error.error.msg);
        }
      );
    this._subscription.add(buyerSurveys);
  }

  getSupplierSurveys(status: any, page: number, searchKey: string) {
    const supplierSurveys = this._supplierApiService
      .getSupplierSurveys(status, page, searchKey)
      .subscribe(
        (response: SupplierSurveysResponse) => {
          this.mapSurveys(response.surveys, page);
        },
        (error: any) => {
          this._fetchSurveys = true;
          this._toastr.error(error.error.msg);
        }
      );
    this._subscription.add(supplierSurveys);
  }

  mapSurveys(response: Array<any>, page: number) {
    if (page === 1) {
      this.surveys = [];
    }
    const surveys: SurveyListing[] = response.map((survey: any) => {
      let mappedSurvey = this.mapApiFields(survey);
      mappedSurvey.inProgress = this.getInProgress(mappedSurvey);
      mappedSurvey.averageCpi = this.getAverageCpi(mappedSurvey);
      mappedSurvey.epc = mappedSurvey.epc || 0;
      mappedSurvey.healthCheck = survey.healthCheck;
      mappedSurvey.currentIR = this.getCurrentIR(mappedSurvey);
      mappedSurvey.survey_launch_date = survey.survey_launch_date
        ? new Date(survey.survey_launch_date)
        : null;
      mappedSurvey.billing_id = survey.billing_id || '';
      return mappedSurvey;
    });
    this._page = page;
    this.surveys = [...this.surveys, ...surveys];

    if (this.tab.count !== 0) {
      this._canLoadMore = this.surveysLength() < this.tab.count ? true : false;
    }

    this._fetchSurveys = true;
    this.tableNextLoader = false;
  }

  surveysLength() {
    return new SurveysLength(this.surveys).calculate();
  }

  title(surveyTitle: string): string {
    if (this.widgets.some((widget) => widget.key === WIDGETS.multiCountry)) {
      const parts = surveyTitle.split('- MC');

      if (parts.length == 1) {
        return parts[0];
      }

      return `${parts[0]}- MC`;
    }

    return surveyTitle;
  }

  mapApiFields(survey: any, mappedSurvey: any = {}) {
    if (!survey) {
      return false;
    }

    let mapper: any =
      this.userType === 'supplier'
        ? Constants.SUPPLIER_V2_SURVEY_MAP
        : Constants.BUYER_V2_SURVEY_MAP;

    for (const [key, value] of Object.entries(survey)) {
      if (typeof value === 'object' && mapper[key]) {
        this.mapApiFields(value, mappedSurvey);
      } else {
        if (mapper[key]) {
          mappedSurvey[mapper[key]] = value;
        }
      }
    }
    return mappedSurvey;
  }

  getInProgress(survey: any): number | string {
    if (this.surveyStatus[survey.status] === 'Draft') {
      return 'NA';
    }
    return survey.inProgress || 0;
  }

  getAverageCpi(survey: any): number | string {
    if (this.surveyStatus[survey.status] === 'Draft') {
      return 'NA';
    }

    if (survey.fielded > 0 || this.userType === 'supplier') {
      return survey.averageCpi || 0;
    }

    return survey.aCpi || 0;
  }

  getCurrentIR(survey: any): number | string {
    if (this.surveyStatus[survey.status] === 'Draft' || !survey.currentIR) {
      return 'NA';
    }

    return `${survey.currentIR}%`;
  }

  getColspan(): number {
    return this.tableHeaders.length + this.widgets.length + 1;
  }

  async openStatusModal(surveyId: number, status: string, billingId: string) {
    const isBillingConfirmationRequired = this._isBillingConfirmationRequired(
      status,
      billingId
    );
    if (isBillingConfirmationRequired) {
      const shouldProceed = await this._billingService.openBillingNumberModal();
      if (!shouldProceed) {
        return;
      }
    }
    const [isProceed, isBulkInvoicingCase, surveysToClose, surveysToInvoice] =
      await this._checkIfItisBulkInvoicingCase(surveyId, status, billingId);
    if (!isProceed) {
      return;
    }
    if (!isBulkInvoicingCase) {
      return this.openSingleStatusUpdateModal(surveyId, status);
    }
    return this._updateSurveyStatuses(
      surveyId,
      surveysToClose,
      surveysToInvoice
    );
  }

  private _isBillingConfirmationRequired(status: string, billingId: string) {
    const numberedStatus = Number(this._commonService.getSurveyStatus(status));
    return !!(
      !this._auth.buyerConfig?.enableMandatoryBillingNumber &&
      !billingId &&
      numberedStatus === SurveyStatus.Invoice
    );
  }

  private async _checkIfItisBulkInvoicingCase(
    currentSurveyId: number,
    status: string,
    billingId: string
  ): Promise<[boolean, boolean, SurveyUpdate[], SurveyUpdate[]]> {
    const numberedStatus = Number(this._commonService.getSurveyStatus(status));
    if (numberedStatus !== SurveyStatus.Invoice) {
      return [true, false, [], []];
    }
    return this._checkAndShowModalIfSurveysWithSamePo(
      currentSurveyId,
      numberedStatus,
      billingId
    );
  }

  private async _checkAndShowModalIfSurveysWithSamePo(
    currentSurveyId: number,
    status: SurveyStatus,
    billingId: string
  ) {
    let notInvoicedSurveysWithSamePo =
      await this._fetchLaunchedAndNotInvoicedSurveysWithSamePo(billingId);
    notInvoicedSurveysWithSamePo = notInvoicedSurveysWithSamePo.filter(
      (s) => Number(s.ps_survey_id) !== Number(currentSurveyId)
    );
    if (!notInvoicedSurveysWithSamePo.length) {
      return this._bulkInvoiceHelperService.decideSurveysToChangeStatusOf(
        status,
        BULK_STATUS_CHANGE_MODAL.NO_BULK_INVOICING_ACTION,
        [],
        [currentSurveyId]
      );
    }
    const action = await this._showModalIfInvoicedSurveysWithSamePo(
      notInvoicedSurveysWithSamePo
    );
    return this._bulkInvoiceHelperService.decideSurveysToChangeStatusOf(
      status,
      action,
      notInvoicedSurveysWithSamePo,
      [currentSurveyId]
    );
  }

  private async _fetchLaunchedAndNotInvoicedSurveysWithSamePo(
    billingId: string
  ) {
    if (!billingId) {
      return [];
    }
    return this._buyerApiService
      .getLaunchedAndNotInvoicedSurveysWithSamePo(billingId)
      .toPromise();
  }

  private async _showModalIfInvoicedSurveysWithSamePo(
    notInvoicedSurveysWithSamePo: BuyerSurveyResponse[]
  ) {
    const notInvoicedSurveyIdsWithSamePo =
      this._bulkInvoiceHelperService.fetchNotInvoicedSortedSurveyIdsWithSamePo(
        notInvoicedSurveysWithSamePo
      );
    return this._modal
      .open(BulkSurveyStatusChangeModalComponent, {
        data: {
          thisAndAllBtnText:
            BULK_STATUS_CHANGE_MODAL.BUTTON.THIS_AND_ALL_LISTED.TEXT
              .SURVEY_DETAIL,
          onlyThisBtnText:
            BULK_STATUS_CHANGE_MODAL.BUTTON.ONLY_THIS.TEXT.SURVEY_DETAIL,
          surveyIds: notInvoicedSurveyIdsWithSamePo.join(', '),
        },
        width: '60%',
      })
      .onClose$.toPromise();
  }

  private async _updateSurveyStatuses(
    currentSurveyId: number,
    surveysToClose: SurveyUpdate[],
    surveysToInvoice: SurveyUpdate[]
  ) {
    await this._buyerApiService
      .updateSurveyStatusesInBulk(surveysToClose)
      .toPromise();
    this._buyerApiService
      .updateSurveyStatusesInBulk(surveysToInvoice)
      .subscribe(
        (data) => {
          const surveyUpdateData = Object.keys(data).map((surveyId) => {
            return {
              surveyId: Number(surveyId),
              status: SurveyStatus.Invoice,
            };
          });
          this.updateSurveyList(surveyUpdateData);
          const isBulkInvoiced = Object.keys(data).length > 1;
          this._toastr.success(
            isBulkInvoiced
              ? notifyMessage.successMessage.SURVEY_DETAIL.BULK_SURVEYS_INVOICED
              : notifyMessage.successMessage.SURVEY_DETAIL.SINGLE_SURVEY_INVOICED.replace(
                  '<SURVEY_ID>',
                  String(currentSurveyId)
                )
          );
        },
        (error) => {
          this._toastr.error(error.error?.ps_api_response_message || error.msg);
        }
      );
  }

  openSingleStatusUpdateModal(surveyId: number, status: string) {
    const surveyUpdateData = { surveyId: surveyId, status: status };
    const modalRef = this._modal.open(ChangeSurveyStatusModalComponent, {
      data: surveyUpdateData,
      width: '450px',
      height: '18.75rem',
    });
    modalRef.onClose$.subscribe((msg) => {
      if (msg === Constants.MODAL_MESSAGE.UPDATE_SURVEY_STATUS) {
        this.updateSurveyList([surveyUpdateData]);
      }
    });
  }

  checkDisableSurveyStatus(survey: SurveyListing) {
    return (
      [SURVEY_STATUS.DRAFT, SURVEY_STATUS.INVOICE].indexOf(survey.status) >
        -1 ||
      this.userType === 'supplier' ||
      (survey.status === SURVEY_STATUS.CLOSED &&
        survey?.company?.generate_invoice === INVOICE_TYPE.MONTHLY)
    );
  }

  updateSurveyList(surveyUpdateData: SurveyUpdate[]) {
    this.surveys = this.surveys.filter(
      (survey) =>
        !surveyUpdateData.some((s) => Number(s.surveyId) === survey.surveyId)
    );
  }

  private _checkIfUserHasAcceptedTOS() {
    const user = this._auth.user;
    const tosAccepted = this._auth?.user?.tos?.accept;
    const internalUser = user && user.eml.includes('@purespectrum.com');
    if (!tosAccepted && !internalUser) {
      this._modal.open(TosComponent);
    }
  }

  surveyDetails(id: number, status: number, samplesType: number) {
    if (status === SurveyStatus.Draft) {
      if (samplesType === Constants.RECONTACT_SURVEY_SAMPLE_TYPE) {
        this._route.navigate(['recontact-survey', id]);
      } else {
        this._route.navigate([`/launch-survey/${id}`]);
      }
    } else {
      this._route.navigate([`/dashboard/${id}`]);
    }
  }

  public cloneSurvey(id: number) {
    this._buyerApiService
      .cloneSurvey(id, this._isServiceBuyerLoggedIn)
      .subscribe(
        (response) => {
          const clonedSurveyId = response.ps_survey_id;
          this._route.navigate([`/surveys/${clonedSurveyId}/edit`], {
            state: {
              isCloneSurvey: true,
              is_race_qualification_removed:
                response.is_race_qualification_removed,
            },
          });
        },
        (error) => {
          let errorMessage =
            'Error encountered while attempting to clone this survey.';
          if (error?.error?.ps_api_response_message) {
            errorMessage = error?.error?.ps_api_response_message;
          }
          this._toastr.error(errorMessage);
        }
      );
  }

  deleteSurvey(surveyId: number, status: number) {
    const modalRef = this._modal.open(ChangeSurveyStatusModalComponent, {
      data: { surveyId, status, toDelete: true },
      width: '450px',
      height: '18.75rem',
    });
    modalRef.onClose$.subscribe((msg) => {
      if (msg === Constants.MODAL_MESSAGE.DELETE_SURVEY) {
        this.surveys.splice(
          this.surveys.findIndex((i: SurveyListing) => {
            return i.surveyId === surveyId;
          }),
          1
        );
      }
    });
  }

  public openDropdown() {
    this.canClose = false;
  }

  public close() {
    this.canClose = true;
  }

  createTCSurvey(type: string, requestFrom: string, surveyId: number) {
    let surveyData = {} as Survey;
    let tcSurveys: Array<TcResponse> = [];
    let surveyQuotas: any = [];
    const params = {
      page: 1,
      limit: Constants.QUOTAPAGELIMIT,
      search: '',
    };
    forkJoin({
      surveyDataResponse: this._buyerApiService.getSurvey(surveyId),
      trafficChannelSurveysResponse:
        this._buyerApiService.getTrafficChannelData(surveyId),
      surveyQuotasResponse: this._buyerApiService.getQuotaDetails(
        surveyId,
        params
      ),
    }).subscribe(
      ({
        surveyDataResponse,
        trafficChannelSurveysResponse,
        surveyQuotasResponse,
      }) => {
        surveyData =
          this._surveyPayloadService.mapBuyerSurvey(surveyDataResponse);
        tcSurveys = trafficChannelSurveysResponse;
        surveyQuotas = surveyQuotasResponse.quota;
        const TCModalData = {
          type: type,
          survey: surveyData,
          surveyQuotas: surveyQuotas,
          tcSurveys: tcSurveys,
        };
        this._modal
          .open(TrafficChannelModalComponent, {
            data: TCModalData,
            width: '60%',
          })
          .onClose$.subscribe((message) => {
            if (message === 'Created') {
              this._toastr.success(
                `Traffic Channel Survey Created for Survey ${surveyId}`
              );
            }
          });
      }
    );
  }

  public downloadTransactionReport(surveyId: number) {
    this._subscription.add(
      this._buyerService
        .downloadTransactionReport(surveyId, this.includeTestTransactions)
        .subscribe(
          () => {
            console.log('success');
          },
          () => {
            this._toastr.error(
              notifyMessage.errorMessage.DOWNLOAD_REPORT_ERROR
            );
          }
        )
    );
  }

  public openDashboardPreferenceModal() {
    this._modal.open(DashboardPreferenceModalComponent, {
      height: '80%',
      width: '80%',
    });
  }

  private _getUserDashboardPreferences() {
    if (this.userType === 'supplier') {
      this.tableHeaders =
        Constants.SUPPLIER_SURVEY_LISTING_TABLE_HEADERS as DashboardPreference[];
    } else {
      const userId = this._auth.user?.id || 0;
      this._dashboardPreferenceService
        .getDashboardPreference(userId, this._isServiceBuyerLoggedIn)
        .subscribe(
          (response: DashboardPreference[]) => {
            const isMobile = this._deviceService.isMobile();
            this._mapTableHeaders(response, isMobile);
            this.widgets = Widget.extract(response, isMobile);
          },
          (error) => {
            this._toastr.error(error.error.msg);
          }
        );
    }
  }

  private _mapTableHeaders(
    dashboardPreferences: DashboardPreference[],
    isMobile: boolean
  ) {
    this.tableHeaders = dashboardPreferences.filter((dashboardPreference) => {
      return (
        !Object.values(WIDGETS).includes(dashboardPreference.key) &&
        dashboardPreference.selected &&
        ((isMobile && dashboardPreference.mobile) ||
          (!isMobile && dashboardPreference.desktop))
      );
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
