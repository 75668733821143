import { DeleteMcSurveyModalTemplate } from './delete-mc-survey-modal.template';
import { DeleteSurveyModalTemplate } from './delete-survey-modal.template';
import { StatusChangeModalTemplate } from './status-change-modal.template';

export interface ChangeSurveyModalTemplateData {
  title: string;
  body: string;
  subtext?: string;
  close: string;
  delete?: string;
}

export interface ChangeSurveyModalTemplate {
  getData(): ChangeSurveyModalTemplateData;
}

export interface ChangeSurveyModalData {
  surveyId: number;
  status: string;
  toDelete: boolean;
  isMc: boolean;
  survey_pause_threshold?: number;
  is_survey_pause_threshold_triggered?: boolean;
  currentCpi?: number;
}

export function changeSurveyTemplateFactory(
  data: ChangeSurveyModalData
): ChangeSurveyModalTemplate {
  if (data.isMc && data.toDelete) {
    return new DeleteMcSurveyModalTemplate(data);
  }

  if (data.toDelete) {
    return new DeleteSurveyModalTemplate(data);
  }

  return new StatusChangeModalTemplate(data);
}
