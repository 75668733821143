<main
  class="container"
  [ngClass]="{
    'pds-modular-header-info':
      userType === 'supplier' && survey.isPDSEnabled && survey.isModularSurvey,
    'pds-header-info':
      userType === 'supplier' && (survey.isPDSEnabled || survey.isModularSurvey)
  }"
>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div
        class="survey-detail-back-btn"
        *ngIf="!survey.channelType; else TCParent"
      >
        <a (click)="navigateToDashboard()">
          <i class="fa fa-angle-left" aria-hidden="true"></i>Back to
          {{ surveyStatus[survey.status] }} Surveys</a
        >
        <a
          *ngIf="showInvoiceTab()"
          class="pull-right invoiceSurveyBtn"
          id="invoiceBtn"
          [class.disabled]="!isChildTCsClosed"
          (click)="invoiceSurvey()"
        >
          <div
            *ngIf="!isChildTCsClosed"
            class="spinner-border text-primary"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          Invoice Survey
          <p id="wait-child-tcs">
            Waiting child traffic channels status change
          </p>
        </a>
        <a
          *ngIf="
            survey.status === surveyStatus.Invoice && userType !== 'supplier'
          "
          class="pull-right invocedSurvey"
        >
          Survey Invoiced
        </a>
      </div>
      <ng-template #TCParent>
        <div class="survey-detail-back-btn">
          <a
            routerLink="/dashboard/{{ survey.mcProjectId || survey.projectId }}"
          >
            <i class="fa fa-angle-left" aria-hidden="true"></i>Back to Surveys#
            {{ survey.mcProjectId || survey.projectId || '' }}
          </a>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="row survey-detail-header">
    <div class="col-md-6">
      <p>Survey# {{ survey.id }}</p>
      <div class="survey-status">
        <button
          class="menu-btn"
          [psUiDropdownToggleFor]="surveysStatus"
          [disabled]="checkDisableSurveyStatus(survey)"
          [ngClass]="{
            greyOutInvoiceButton: survey.status === surveyStatus.Invoice
          }"
        >
          {{ surveyStatus[survey.status] }}
        </button>
        <ps-ui-dropdown class="status-dropdown" #surveysStatus="psUiDropdown">
          <div *ngFor="let statusButton of statusButtons">
            <button
              psUiDropdownItem
              *ngIf="statusButton.condition.indexOf(survey.status) > -1"
              (click)="openStatusModal(survey.id, statusButton.value)"
            >
              {{ statusButton.value }}
            </button>
          </div>
        </ps-ui-dropdown>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <div *ngIf="buyerSurvey.mc?.SVP" class="country-select-dropdown">
        <ps-ui-select-dropdown
          [disabled]="!buyerSurvey.mc.surveys.length"
          [loading]="false"
          [dropdownItems]="buyerSurvey.mc.surveys"
          [field]="'countryName'"
          [showFlag]="true"
          [flagCountryCodeField]="'countryCode'"
          [flagLanguageCodeField]="'languageCode'"
          [clearSearch]="false"
          [enableSearch]="false"
          class="uppercase"
          [labelNone]="this.selectedSvpCountry.countryName || 'Select Country'"
          labelAlignment="start"
          [(ngModel)]="selectedSvpCountry"
          (selectedItem)="selectSvpCountry($event)"
        >
        </ps-ui-select-dropdown>
      </div>
      <ps-ui-flag
        *ngIf="!buyerSurvey.mc?.SVP"
        class="pull-right"
        [countryCode]="survey.countryCode"
        [countryName]="survey.countryName"
      ></ps-ui-flag>
    </div>
  </div>
  <div class="row survey-title-content">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <h2>{{ survey.title }}</h2>
    </div>
  </div>
  <ps-ui-survey-top-view
    [survey]="survey"
    [userRole]="userType"
  ></ps-ui-survey-top-view>
  <div class="row">
    <!-- Quota Tab needs to modify for supplier, Curently working for only buyer -->
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <ng-container *ngIf="!isEmptySurvey()">
        <ps-ui-manage-survey-tabs
          [survey]="survey"
          [auth]="auth"
          [targetedActiveTabKey]="activeSurveyDashboardTabKey"
          [selectedSvpChildSurveyId]="selectedSvpCountry.surveyId"
        ></ps-ui-manage-survey-tabs>
      </ng-container>
    </div>
  </div>
</main>
<ps-edit-survey-footer
  *ngIf="userType !== 'supplier' && !isEmptySurvey()"
  [surveyId]="surveyId"
  [surveyChannelType]="survey.channelType"
  [survey]="buyerSurvey"
  [selectedSvpCountry]="selectedSvpCountry"
  [isQbpChildSurvey]="isQbpChildSurvey"
  [isRecontact]="isRecontact"
  [st]="survey.status"
></ps-edit-survey-footer>
